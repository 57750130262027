import AuthService from "./AuthService";
import moment from "moment";
const Auth = new AuthService();

const Mispichoshelper = {
  openLink: function (link) {
    window.open(Auth.createAuthLink(link));
  },
  playSounds: function ({
    order,
    type,
    playerPendiente,
    playerOportunidad,
    playerDemorado,
    endSchedule,
  }) {
    var playPromise = false;
    if (order.estado === "procesando") {
      playPromise = playerPendiente.play();
    } else if (type === "pedidoSorteo") {
      playPromise = playerOportunidad.play();
    } else if (order.estado === "preparando" && endSchedule) {
      playPromise = playerDemorado.play();
    } else {
      playPromise = playerOportunidad.play();
    }

    if (playPromise && playPromise !== undefined) {
      playPromise
        .then((_) => {
          playerPendiente.onended = () => {
            playerPendiente.pause();
            playerPendiente["currentTime"] = 0;
          };
          playerOportunidad.onended = () => {
            playerOportunidad.pause();
            playerOportunidad["currentTime"] = 0;
          };
          playerDemorado.onended = () => {
            playerDemorado.pause();
            playerDemorado["currentTime"] = 0;
          };
          var showAudioPermission = false;
          var params = {
            playerPendiente: playerPendiente,
            playerOportunidad: playerOportunidad,
            playerDemorado: playerDemorado,
            showAudioPermission: showAudioPermission,
          };
          return params;
        })
        .catch((error) => {
          console.log("sin permisos para habilitar audio ", error);
          var showAudioPermission = true;
          return showAudioPermission;
        });
    }
  },
  validateDate: function (order) {
    //let now = moment('2020-08-23 10:00:00');
    let response = {};
    let now = moment();
    let fechaIni = moment(
      order.fecha_pura +
      " " +
      (order.horaInicio > 9 ? order.horaInicio : "0" + order.horaInicio) +
      ":00:00"
    );
    let fechaFin = moment(
      order.fecha_pura +
      " " +
      (order.horaFin > 9 ? order.horaFin : "0" + order.horaFin) +
      ":00:00"
    );
    //console.log("NOW -------> ", now.format("YYYY-MM-DD h:mm:ss a"));
    //console.log("INI -------> ", fechaIni.format("YYYY-MM-DD h:mm:ss a"));
    //console.log("FIN -------> ", fechaFin.format("YYYY-MM-DD h:mm:ss a"));

    // Dia
    if (
      moment(now.format("YYYY-MM-DD")).isSame(fechaIni.format("YYYY-MM-DD"))
    ) {
      //console.log("DIA -------> SI");
      response.day = true;
    } else {
      response.day = false;
    }

    // Franja horaria
    if (now.isBefore(fechaIni)) {
      console.log("DIA -------> NO");
      response.time = "antes";
    }

    if (now.isSameOrAfter(fechaIni) && now.isSameOrBefore(fechaFin)) {
      console.log("LOGICA -------> DURANTE");
      response.time = "durante";
    }

    if (now.isAfter(fechaFin)) {
      console.log("LOGICA -------> DESPUES");
      response.time = "despues";
    }
    return response;
  },
  addMinutes: function (date, minutes) { return new Date(date.getTime() + minutes * 60000); },
  restMinutes: function (date, minutes) { return new Date(date.getTime() - minutes * 60000); },
  ClientAPI: {
    getBrandsHome: function (params) { return Auth.getPublic(CLIENT_HOME_BRANDS_GET, params); },
  },
  Marketing: {
    getLandings: function (params) { return Auth.get(MARKETING_GET_LANDINGS, params); },
    getLanding: function (params) { return Auth.get(MARKETING_GET_LANDING, params); },
    editLanding: function (params) { return Auth.post(MARKETING_EDIT_LANDING, params); },
    newLanding: function (params) { return Auth.post(MARKETING_NEW_LANDING, params); },
  },
  Master: {

    getDashboard: function (params) { return Auth.get(GET_MASTER_DASHBOARD, params); },

    getServiceLevelMasterDashboard: function (params) { return Auth.get(GET_SERVICE_LEVEL_MASTER_DASHBOARD, params); },

    // Dashboard Fulfillment
    getPetshopDelegationsAndSharesFulfillment: function (params) { return Auth.get(GET_PETSHOP_SHARES_DELEGATIONS_DASHBOARD, params); },
    getProdsSoldAndDelegationsByCatFulfillment: function (params) { return Auth.get(GET_PRODS_SALES_DELEGATIONS_CATEGORY_DASHBOARD, params); },
    getProdsSoldAndDelegationsByBrandFulfillment: function (params) { return Auth.get(GET_PRODS_SALES_DELEGATIONS_BRAND_DASHBOARD, params); },
    getProdsSoldAndDelegationsByProdVarFulfillment: function (params) { return Auth.get(GET_PRODS_SALES_DELEGATIONS_PROD_VAR_DASHBOARD, params); },
    getOrdersStuckedByZoneFulfillment: function (params) { return Auth.get(GET_ORDERS_STUCKED_BY_ZONE_DASHBOARD, params); },

    getDeliveryDashboard: function (params) { return Auth.get(GET_DELIVERY_MASTER_DASHBOARD, params); },
    getOutTimePetshops: function (params) { return Auth.get(GET_REPORT_OUT_TIME_PETSHOPS, params); },
    getFulfillmentDashboard: function (params) { return Auth.get(GET_FULFILLMENT_MASTER_DASHBOARD, params); },
    getAssumedValuesDashboard: function (params) { return Auth.get(GET_ASSUMED_VALUES_DASHBOARD, params); },

  },
  Accountability: {
    markOrderAsSuspicius: function (params) { return Auth.post(ACCOUNTABILITY_POST_ORDER_SUSPICIUS, params); },
    getClientInfoPayment: function (params) { return Auth.get(ACCOUNTABILITY_GET_CLIENT_PAYMENT, params); },
    getDashboard: function (params) { return Auth.get(ACCOUNTABILITY_GET_DASHBOARD, params); },
  },
  Petshop: {
    getXubioClientById: function (params) { return Auth.get(PETSHOP_GET_XUBIO_CLIENTS, params); },
    getNotificationHistorial: function (params) { return Auth.get(PETSHOP_NOTIFICATION_HISTORIAL_GET, params); },
    getReportStatics: function (params) { return Auth.get(PETSHOP_GET_REPORT_STATICS, params); },
    getDashboard: function (params) { return Auth.get(PETSHOP_GET_DASHBOARD, params); },
    getOrders: function (params) { return Auth.get(PETSHOP_GET_ORDERS, params); },
    getMyOrders: function (params) { return Auth.get(PETSHOP_GET_MY_ORDERS, params); },
    getOrderById: function (params) { return Auth.get(PETSHOP_GET_ORDER_BY_ID, params); },
    getNews: function (params) { return Auth.get(PETSHOP_GET_NEWS, params); },
    getConsultaEntrega: function (params) { return Auth.get(PETSHOP_COMPRA_CONSULTA_ENTREGA_GET, params); },
    getConsultaStock: function (params) { return Auth.get(PETSHOP_COMPRA_CONSULTA_STOCK_GET, params); },
    getAskPhone: function (params) { return Auth.get(PETSHOP_CONSULTA_TELEFONO_GET, params); },
    getAccountRegistry: function (params) { return Auth.get(PETSHOP_ACCOUNT_REGISTRY_GET, params); },
    getPayingWeekly: function (params) { return Auth.get(PETSHOP_PAYING_WEEKLY_GET, params); },
    getPayingMonthly: function (params) { return Auth.get(PETSHOP_PAYING_MONTHLY_GET, params); },
    getSpecialDays: function (params) { return Auth.get(PETSHOP_SPECIAL_DAYS_GET, params); },
    getPolygon: function (params) { return Auth.get(PETSHOP_POLIGONO_GET, params); },
    getProducts: function (params) { return Auth.get(PETSHOP_PRODUCTOS_GET, params); },
    getSchedule: function (params) { return Auth.get(PETSHOP_SCHEDULE_GET, params); },
    getProflie: function (params) { return Auth.get(PETSHOP_PROFILE_GET, params); },
    getReporteLiquidacion: function (params) { return Auth.getLink(REPORTE_LIQUIDACION_GET, params); },
    getReporteComisiones: function (params) { return Auth.getLink(REPORTE_COMISIONES_GET, params); },
    getReporteDescuentos: function (params) { return Auth.getLink(REPORTE_DESCUENTOS_GET, params); },
    postAddress: function (params) { return Auth.post(PETSHOP_ADDRESS_POST, params); },
    postProfile: function (params) { return Auth.post(PETSHOP_PROFILE_POST, params); },
    postSchedule: function (params) { return Auth.post(PETSHOP_SCHEDULE_POST, params); },
    postProducts: function (params) { return Auth.post(PETSHOP_PRODUCTOS_POST, params); },
    potSendInvoiceByEmail: function (params) { return Auth.post(PETSHOP_INVOICE_EMAIL_POST, params); },
    postSpecialDays: function (params) { return Auth.post(PETSHOP_SPECIAL_DAYS_POST, params); },
    postConsultaStock: function (params) { return Auth.post(PETSHOP_COMPRA_CONSULTA_STOCK_POST, params); },
    postConsultaEntrega: function (params) { return Auth.post(PETSHOP_COMPRA_CONSULTA_ENTREGA_POST, params); },
    postOrderDelegate: function (params) { return Auth.post(PETSHOP_COMPRA_DELEGAR_POST, params); },
    postOrderPreparando: function (params) { return Auth.post(PETSHOP_COMPRA_ACEPTAR_POST, params); },
    postOrderProblem: function (params) { return Auth.post(PETSHOP_COMPRA_PROBLEMA_POST, params); },
    postOrderComplete: function (params) { return Auth.post(PETSHOP_COMPRA_ENTREGADO_POST, params); },
    postSendPolygon: function (params) { return Auth.post(PETSHOP_POLIGONO_POST, params); },
    postInformNotificationViewed: function (params) { return Auth.post(PETSHOP_NOTIFICATION_VIEWED_POST, params); },
    postSurveyResponse: function (params) { return Auth.post(PETSHOP_NOTIFICATION_SURVEY_VIEWED_POST, params); },
    alertConsoleOpen: function (params) { return Auth.post(PETSHOP_CONSOLE_OPEN_POST, params); },
    postDelegatePreparingOrder: function (params) { return Auth.post(PETSHOP_DELEGATE_PREPARING_ORDER_POST, params); },
    validateOrder: function (params) { return Auth.post(PETSHOP_COMPRA_VALIDATE_POST, params); },
    getMegaBuscador: function (params) { return Auth.get(PETSHOP_MEGA_BUSCADOR_GET, params); },
    getTransporters: function (params) { return Auth.get(GET_TRANSPORTERS, params); },
    postTransporters: function (params) { return Auth.post(POST_TRANSPORTERS, params); },
    postUpdateShosLabelMoova: function (params) { return Auth.post(UPDATE_SHOW_LABEL_MOOVA, params); },
    getReportSoldProducts: function (params) { return Auth.get(PETSHOP_GET_REPORT_SOLD_PRODUCTS, params); },
    getProductsCategory: function (params) { return Auth.get(PETSHOP_PRODUCTS_CATEGORY, params); },
    getProductsCategoryByBrands: function (params) { return Auth.get(PETSHOP_PRODUCTS_CATEGORY_BRANDS, params); },
    autoAceptOrdersPost: function (params) { return Auth.post(PETSHOP_AUTO_ACEPT_ORDERS, params) },
    getTransportersManualAssignation: function (params) { return Auth.get(PETSHOP_TRANSPORTERS_MANUAL_ASSIGNATION, params); },
    postOrdersToTransporter: function (params) { return Auth.post(PETSHOP_TRANSPORTERS_ASSIGN_ORDERS, params); },
    postRemoveOrdersFromTransporter: function (params) { return Auth.post(PETSHOP_TRANSPORTERS_REMOVE_ASSIGNED_ORDERS, params); }, //TODO: eliminar cuando se saque modulo asignacion viejho
    resetTransporterOrders: function (params) { return Auth.post(PETSHOP_TRANSPORTERS_RESET_TRANSPORTER_ORDERS, params); },
    getSchedulesActive: function (params) { return Auth.get(PETSHOP_SCHEDULES_ACTIVES, params); },
    postPickUpOrders: function (params) { return Auth.post(PICK_UP_ORDERS, params); },
    getNotes: function (params) { return Auth.get(PETSHOP_GET_NOTES, params); },
    getClientPayment: function (params) { return Auth.get(PETSHOP_GET_CLIENT_PAYMENT, params); },
    getReturnOrders: function (params) { return Auth.get(PETSHOP_GET_RETURN_ORDERS, params) },
    resolveReturnOrder: function (params) { return Auth.post(PETSHOP_POST_RESOLVE_RETURN_ORDERS, params) },
    getTransporterPetshopLogistic: function (params) { return Auth.get(GET_PETSHOP_LOGISTIC_TRANSPORTER, params); },
    getOrdersPetshopLogistic: function (params) { return Auth.get(GET_PETSHOP_DELIVERY_ORDERS, params); },
    getOrdersPetshopLogisticIndicators: function (params) { return Auth.get(GET_PETSHOP_DELIVERY_ORDERS_INDIC, params); },
    getMissingDeliveryTime: function (params) { return Auth.get(GET_MISSING_DELIVERY_TIME, params); },
    // gestion pedidos
    getPreparationOrders: function (params) { return Auth.get(PETSHOP_GET_PREPARATION_ORDERS, params); },
    getPreparationOrdersIndicators: function (params) { return Auth.get(PETSHOP_GET_PREPARATION_ORDERS_INDICATORS, params); },
    postPacked: function (params) { return Auth.post(PETSHOP_POST_FINISH_PACKED, params); },
    getOrdersLogisticAssignment: function (params) { return Auth.get(GET_ORDERS_LOGISTIC_ASSIGNMENT, params); },
    getPrePickingInformation: function (params) { return Auth.get(PETSHOP_GET_PRE_PICKING_INFO, params); },
    informPrint: function (params) { return Auth.post(PETSHOP_POST_INFORM_PRINT, params); },
    postPrintComprobantMovement: function (params) { return Auth.post(PETSHOP_POST_PRINT_COMPROBANT, params); },
    postPrintComprobantMovementMassive: function (params) { return Auth.post(PETSHOP_POST_PRINT_COMPROBANT_MASSIVE, params); },
    getZonesFilterPetshop: function (params) { return Auth.get(PETSHOP_GET_ZONES_FILTERS, params); },
    //Inventory
    getInventoryMovements: function (params) { return Auth.get(PETSHOP_GET_INVENTORY_MOVEMENTS, params) },
    getDeliveryNotes: function (params) { return Auth.get(PETSHOP_GET_DELIVERY_NOTES, params) },
    getDeliveryNotesInventory: function (params) { return Auth.get(PETSHOP_GET_DELIVERY_NOTES_INVENTORY, params) },
    getIndicatorsData: function (params) { return Auth.get(PETSHOP_GET_INDICATORS_DATA, params) },
    getInventoryMotives: function (params) { return Auth.get(PETSHOP_GET_INVENTORY_MOTIVES, params) },
    getBrandProviders: function (params) { return Auth.get(PETSHOP_GET_BRAND_PROVIDERS, params) },
    getProductByCode: function (params) { return Auth.get(PETSHOP_GET_PRODUCT_BY_CODE, params) },
    postInflow: function (params) { return Auth.post(PETSHOP_POST_INFLOW, params) },
    postOutflow: function (params) { return Auth.post(PETSHOP_POST_OUTFLOW, params) },
    saveImageByDeliveryNote: function (params) { return Auth.post(PETSHOP_SAVE_IMAGE_BY_DELIVERY_NOTE, params) },
    getReservedProducts: function (params) { return Auth.get(PETSHOP_GET_RESERVED_PRODUCTS, params) },
    skuBulkLoad: function (params) { return Auth.post(SKU_BULK_LOAD, params) },
    checkUserActionIdentifier: function (params) { return Auth.get(CHECK_USR_ACTION_IDENTIFIER, params) },
  },
  AccountManager: {
    getInvoices: function (params) { return Auth.get(ACCOUNT_MANAGER_INVOICES_GET, params); },
    getInvoicesIncome: function (params) { return Auth.get(ACCOUNT_MANAGER_INVOICES_INCOME_GET, params); },
    getPetshopList: function (params) { return Auth.get(ACCOUNT_MANAGER_PETSHOPLIST_GET, params); },
    getTransfers: function (params) { return Auth.get(ACCOUNT_MANAGER_TRANSFERS_GET, params); },
    getTxtBBVA: function (params) { return Auth.get(ACCOUNT_MANAGER_BBVA_TXT_GET, params); },
    getXubioClients: function (params) { return Auth.get(ACCOUNT_GET_XUBIO_CLIENTS, params); },
    getPetshopProfile: function (params) { return Auth.get(ACCOUNT_GET_PETSHOP_PROFILE_GET, params); },
    postBonificaPetshop: function (params) { return Auth.post(ACCOUNT_MANAGER_ORDER_NO_COMISSION_POST, params); },
    postTransferMoney: function (params) { return Auth.post(ACCOUNT_MANAGER_TRANSFER_MONEY_POST, params); },
    postTransferMoneyPrisma: function (params) { return Auth.post(ACCOUNT_MANAGER_TRANSFER_MONEY_PRISMA_POST, params); },
    postAllowChangeData: function (params) { return Auth.post(ACCOUNT_ALLOW_CHANGE_DATA_POST, params); },
    postCashRow: function (params) { return Auth.post(ACCOUNT_CASH_ROW_POST, params); },
    invoiceCreate: function (params) { return Auth.post(ACCOUNT_INVOICE_CREATE, params); },
    postInvoiceIncomeCreate: function (params) { return Auth.post(ACCOUNT_INVOICE_INCOME_CREATE, params); },
    createAllInvoice: function (params) { return Auth.post(ACCOUNT_CREATE_ALL_INVOICE, params); },
    createAllInvoiceIncome: function (params) { return Auth.post(ACCOUNT_CREATE_ALL_INVOICE_INCOME, params); },
    postPausePetshop: function (params) { return Auth.post(ACCOUNT_PAUSE_POST, params); },
    postInactivePetshop: function (params) { return Auth.post(ACCOUNT_INACTIVE_POST, params); },
    postChangeDateToCalculateIndexMp: function (params) { return Auth.post(ACCOUNT_CHANGE_DATE_CALCULATE_INDX_POST, params); },
    sendPetCategory: function (params) { return Auth.post(SEND_PET_CATEGORY, params); },
    activeAutoAcept: function (params) { return Auth.post(SEND_ACTIVE_AUTO_ACEPT, params); },
    checkNoCaeGenerated: function (params) { return Auth.post(CHECK_NO_CAE_GENERATED, params); },
    getPetshopCommissions: function (params) { return Auth.get(GET_PETSHOP_COMMISSIONS, params); },
    updatePetshopCommissions: function (params) { return Auth.post(UPDATE_PETSHOP_COMMISSIONS, params); },
  },
  ControlLogistic: {
    getDashboardInformation: function (params) { return Auth.get(CONTROL_LOGISTIC_DASHBOARD, params); },
    getTransporterPetshopLogistic: function (params) { return Auth.get(ATENCION_GET_PETSHOP_LOGISTIC_TRANSPORTER, params); },
    getOrdersPetshopLogistic: function (params) { return Auth.get(ATENCION_GET_PETSHOP_DELIVERY_ORDERS, params); },
    getOrdersPetshopLogisticIndicators: function (params) { return Auth.get(ATENCION_GET_PETSHOP_DELIVERY_ORDERS_INDICATORS, params); },
    getMissingDeliveryTime: function (params) { return Auth.get(ATENCION_GET_MISSING_DELIVERY_TIME, params); },
    getPreparationOrders: function (params) { return Auth.get(ATENCION_GET_PREPARATION_ORDERS, params); },
    getPreparationOrdersIndicators: function (params) { return Auth.get(ATENCION_GET_PREPARATION_ORDERS_INDICATORS, params); },
    getZonesFilterPanel: function (params) { return Auth.get(GET_ZONES_FILTERS, params); },
    getOrdersLogisticAssignmentPanel: function (params) { return Auth.get(GET_ORDERS_LOGISTIC_ASSIGNMENT_PANEL, params); },
  },
  Commercial: {
    getLandings: function (params) { return Auth.get(LANDING_GET, params); },
    saveLanding: function (params) { return Auth.post(LANDING_SAVE, params); },
    activeLanding: function (params) { return Auth.post(LANDING_ACTIVE_POST, params); },
    inactiveLanding: function (params) { return Auth.post(LANDING_INACTIVE_POST, params); },
    productsVariations: function (params) { return Auth.get(PRODUCTS_VARIATIONS_GET, params); },
    sendPriceClevertap: function (params) { return Auth.post(PRODUCTS_CLEVERTAP_CHANGE_PRICE, params); },
    postCombinedProduct: function (params) { return Auth.post(COMBINED_PRODUCT_POST, params); },
    postChangeStatusCombinedProduct: function (params) { return Auth.post(CHANGE_STATUS_COMBINED_PRODUCT_POST, params); },
    getCombinedProduct: function (params) { return Auth.get(COMBINED_PRODUCT_GET, params); },
    postDeleteCombinedProduct: function (params) { return Auth.post(DELETE_COMBINED_PRODUCT_POST, params); },
    getSliders: function (params) { return Auth.get(GET_SLIDERS, params); },
    postSliders: function (params) { return Auth.post(POST_SLIDERS, params); },
    deleteLogicSlider: function (params) { return Auth.post(DELETE_LOGIC_SLIDERS, params); },
    postSfotoData: function (params) { return Auth.post(POST_SFOTO_DATA, params); },
    postCopPics: function (params) { return Auth.post(POST_ADDITIONAL_PICS, params); },
  },
  Transporter: {
    getOrders: function (params) { return Auth.get(TRANSPORTER_GET_ORDERS, params); },
    sendStartDelivery: function (params) { return Auth.post(TRANSPORTER_START_DELIVERY_POST, params); },
    sendCompleteDelivery: function (params) { return Auth.post(TRANSPORTER_COMPLETE_DELIVERY_POST, params); },
    postDelegateAcceptedOrder: function (params) { return Auth.post(TRANSPORTER_DELEGATE_ACCEPTED_ORDER, params) },
    getConsultStock: function (params) { return Auth.get(TRANSPORTER_GET_CONSULT_STOCK, params) },
  },
  UserChekingAccount: {
    getMoveList: function (params) { return Auth.get(REFERRED_GET_MOVE_LIST, params); },
    getTotalBalance: function (params) { return Auth.get(REFERRED_GET_ALL_BALANCE, params); },
    getReferredList: function (params) { return Auth.get(REFERRED_GET_LIST, params); },

  },
  getMisPichosSuspicious: function (params) { return Auth.get(ORDERS_SUSPICIUS, params); },
  getConsultaEntrega: function (params) { return Auth.get(CONSULTA_ENTREGA, params); },
  getConsultaStock: function (params) { return Auth.get(CONSULTA_STOCK, params); },
  getEmails: function (params) { return Auth.get(REPORT_MAIL_GET, params); },
  getConsultaRadio: function (params) { return Auth.get(CONSULTA_RADIO, params); },
  getHorariosDeReprogramacion: function (params) { return Auth.get(REPROGRAMACION_GET_HORARIOS, params); },
  getCompra: function (compraId) { return Auth.get(COMPRA_GET_ONE + "?compra_id=" + compraId); },
  getCompras: function (params = false) { return Auth.get(COMPRA_GET_ALL, params); },
  getOrdersFixer: function (params = false) { return Auth.get(ORDERS_FIXER_GET_ALL, params); },
  getMovimientosCompra: function (params) { return Auth.get(COMPRA_MOVIMIENTOS_GET, params); },
  getUsuarios: function (params) { return Auth.get(USUARIOS_LIST_GET, params); },
  getUserSearch: function (params) { return Auth.get(USUARIO_SEARCH_GET, params); },
  getProducto: function (params) { return Auth.get(PRODUCTO_GET_ONE, params); },
  getPetshopsByProductVariation: function (params) { return Auth.get(PETSHOPS_BY_VARIATION_GET, params); },
  postNewPetshop: function (params) { return Auth.post(PETSHOP_NEW_POST, params); },
  postEditPetshop: function (params) { return Auth.post(PETSHOP_EDIT_POST, params); },
  postPricingProducts: function (params) { return Auth.post(UPDATE_PRICING_PRODUCTS, params); },
  postUpdateProductsPricing: function (params) { return Auth.post(UPDATE_PRODUCTS_PRICING, params); },
  postMatchProducts: function (params) { return Auth.post(MATCH_PRICING_EXTERNAL_PRODUCTS, params); },
  postSaveOrEdit: function (params) { return Auth.post(SAVE_OR_EDIT, params); },
  getListProducts: function (params) { return Auth.get(LIST_PRODUCTS, params); },
  getOneProduct: function (params) { return Auth.get(ONE_PRODUCT, params); },
  getListPreciosExternos: function (params) { return Auth.get(LIST_EMPRESAS_PRECIOS_EXTERNOS, params); },
  getProductos: function () {
    var productos = [
      {
        id: "1",
        marca: "Old Prince",
        nombre: "Derma Defense",
        foto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/mobile_royal-canin-mini-adult_22",
        categoria: "Alimento",
        animal: "perro",
        calidad: "Super Premium",
        medicado: 0,
        empre: 150.41,
        orden: 10,
        acciones: ":D",
      },
      {
        id: "2",
        marca: "Pro Plan",
        nombre: "Puppy Complete Razas Medianas",
        foto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/mobile_royal-canin-mini-adult_22",
        categoria: "Alimento",
        animal: "perro",
        calidad: "Super Premium",
        medicado: 0,
        empre: 150.41,
        orden: 10,
        acciones: ":D",
      },
      {
        id: "3",
        marca: "Dog Chow",
        nombre: "Cachorros",
        foto: "https://s3-sa-east-1.amazonaws.com/mispichos.com.ar/mp_images/desktop_pawise-pack-porta-bolsa-con-linterna--repuesto-de-bolsas-x-3-20-unidades-x-rollo_401",
        categoria: "Alimento",
        animal: "perro",
        calidad: "Super Premium",
        medicado: 0,
        empre: 150.41,
        orden: 10,
        acciones: ":D",
      },
    ];
    return productos;
  },
  getSpecialDay: function (params) { return Auth.get(GET_SPECIAL_DAY, params); },
  deleteSpecialDay: function (params) { return Auth.post(DELETE_SPECIAL_DAY, params); },
  sendSpecialDay: function (params) { return Auth.post(SEND_SPECIAL_DAY, params); },
  sendTreggoStatus: function (params) { return Auth.post(SEND_TREGGO_STATUS, params); },
  getSchedulesActive: function (params) { return Auth.get(SCHEDULES_ACTIVES, params); },
  getScheduleAdmin: function (params) { return Auth.get(SCHEDULE_GET_ADMIN, params); },
  postScheduleAdmin: function (params) { return Auth.post(SCHEDULE_POST_ADMIN, params); },
  getProductosPreciosExternos: function (params) { return Auth.get(GET_LIST_PRODUCTS_PRECIOS_EXTERNOS, params); },
  getProductsVariations: function (params) { return Auth.get(GET_LIST_PRODUCTS_VARIATIONS, params); },
  getDomiciliosPerfil: function (params) { return Auth.get(USUARIO_GET_DOMICILIOS, params); },
  getPetshop: function (params) { return Auth.get(PETSHOP_GET_ALL_GET, params); },
  getUserHappiness: function (params) { return Auth.get(USER_HAPPINESS, params); },
  getUserComments: function (params) { return Auth.get(USER_COMMENTS, params); },
  getPetshopList: function (params) { return Auth.get(PETSHOPLIST_GET_ALL_GET, params); },
  getTransportersFilter: function (params) { return Auth.get(GET_TRANSPORTERS_FILTER, params); },
  getTransportersList: function (params) { return Auth.get(GET_TRANSPORTERS_LIST, params); },
  getLogisticsWithTransportersList: function (params) { return Auth.get(GET_LOGISTICS_WITH_TRANSPORTERS_LIST, params); },
  getLogisticListToPermitLinkWithTransporter: function (params) { return Auth.get(GET_PERMITTED_LOGISTICS, params); },
  getMegaBuscador: function (params) { return Auth.get(MEGA_BUSCADOR_GET, params); },
  getMotivosCompraCancelaciones: function () { return Auth.get(MOTIVOS_COMPRA_CANCELACION); },
  sendOrderToCollector: function (params) { return Auth.post(ASSIGNATION_SEND_ORDER_TO_COLLETOR, params) },
  getMotivosCompraIntervenciones: function () { return Auth.get(MOTIVOS_COMPRA_INTERVENCION); },
  getPanel: function (params) { return Auth.get(PANEL_GET_INDICADORES, params); },
  getPanelFixer: function (params) { return Auth.get(PANEL_FIXER_GET_INDICADORES, params); },
  setCommercialEvent: function (params) { return Auth.post(SET_COMMERCIAL_EVENT, params); },
  setBrandFooterColor: function (params) { return Auth.post(SET_BRAND_FOOTER_COLOR, params); },
  finishCommercialEvent: function () { return Auth.post(FINISH_COMMERCIAL_EVENT); },
  finishColorCommercialEvent: function () { return Auth.post(FINISH_COLOR_COMMERCIAL_EVENT); },
  clearBrandFooterColor: function () { return Auth.post(CLEAR_BRAND_FOOTER_COLOR); },
  getMarcasWithCucardas: function (params) { return Auth.get(MARCAS_WITH_CUCARDAS, params); },
  getCustomColorBrands: function (params) { return Auth.get(MARCAS_WITH_COLORS, params); },
  getCommercialEventImage: function (params) { return Auth.get(GET_CUCARDA, params); },
  getMarcas: function (params) { return Auth.get(MARCAS_GET_ALL, params); },
  getMarcasAbm: function (params) { return Auth.get(MARCAS_ABM_GET_ALL, params); },
  deleteMarca: function (params) { return Auth.post(MARCA_DELETE, params); },
  postMarca: function (params) { return Auth.post(MARCA_POST, params); },
  getCategorias: function (params) { return Auth.get(CATEGORIA_GET_ALL, params); },
  getAttributes: function () { return Auth.get(ATTRIBUTES_GET_ALL); },
  postAttributes: function (params) { return Auth.post(ATTRIBUTES_POST, params); },
  postTypeAttributes: function (params) { return Auth.post(TYPE_ATTRIBUTES_POST, params); },
  deleteAttribute: function (params) { return Auth.post(ATTRIBUTES_DELETE, params); },
  getTypeAttributes: function (params) { return Auth.get(TYPE_ATTRIBUTES_GET_ALL, params); },
  deleteTypeAttribute: function (params) { return Auth.post(TYPE_ATTRIBUTES_DELETE, params); },
  getAnimales: function () { return Auth.get(ANIMALES_GET_ALL); },
  getUsuarioPerfil: function (params) { return Auth.get(USUARIO_GET_ONE, params); },
  getDelegacionesPosibles: function (params = false) { return Auth.get(DELEGACIONES_POSIBLES_GET, params); },
  getComprasPerfil: function (params) { return Auth.get(USUARIO_GET_COMPRAS, params); },
  getCompraSplitProductos: function (params) { return Auth.get(COMPRA_SPLIT_PRODUCTOS_GET, params); },
  getNotifications: function (params) { return Auth.get(NOTIFICATION_GET, params); },
  postNotification: function (params) { return Auth.post(NOTIFICATION_POST, params); },
  getNotes: function (params) { return Auth.get(NOTE_GET, params); },
  getCSNotes: function (params) { return Auth.get(NOTE_CS_GET, params); },
  postNotes: function (params) { return Auth.post(NOTE_POST, params); },
  postNotificationResponse: function (params) { return Auth.post(POST_RESPONSE_NOTIFICATION, params); },
  getNotificationHistorial: function (params) { return Auth.get(NOTIFICATION_HISTORIAL_GET, params); },
  postSendNotification: function (params) { return Auth.post(NOTIFICATION_SEND_POST, params); },
  postLoginAs: function (params) { return Auth.post(USUARIO_LOGIN_AS_POST, params); },
  postCompraCompletada: function (params) { return Auth.post(COMPRA_POST_COMPLETADA, params); },
  postCompraSplit: function (params) { return Auth.post(COMPRA_POST_SPLIT, params); },
  pausaOrder: function (params) { return Auth.post(COMPRA_PAUSE, params); },
  toContactByCs: function (params) { return Auth.post(TO_CONTACT_CS, params); },
  contactedByCs: function (params) { return Auth.post(CONTACTED_CS, params); },
  getMotivosToContactCS: function (params) { return Auth.get(GET_MOTIVOS_TO_CONTACT_CS, params); },
  oportunityOrder: function (params) { return Auth.post(COMPRA_OPORTUNITY, params); },
  postAltaDomicilioPerfil: function (params) { return Auth.post(USUARIO_POST_DOMICILIO, params); },
  postCompraDomicilio: function (params) { return Auth.post(COMPRA_DOMICILIO_POST, params); },
  checkIfAreValidDeliveryDates: function (params) { return Auth.get(COMPRA_CHECK_DOMICILIO, params); },
  postPerfil: function (params) { return Auth.post(USUARIO_PERFIL_POST, params); },
  postCompraComentario: function (params) { return Auth.post(COMPRA_NOTAS_POST, params); },
  postCompraIntervencion: function (params) { return Auth.post(COMPRA_INTERVENCION_POST, params); },
  postReprogramaCompra: function (params) { return Auth.post(REPROGRAMACION_POST, params); },
  postDelegarCompra: function (params) { return Auth.post(DELEGACIONES_MANUAL_POST, params); },
  postEditarProductosCompra: function (params) { return Auth.post(PRODUCTOS_EDITAR_POST, params); },
  postCancelaCompra: function (params) { return Auth.post(COMPRA_CANCELA_POST, params); },
  postProblemOrder: function (params) { return Auth.post(COMPRA_PROBLEM_POST, params); },
  postFollowOrder: function (params) { return Auth.post(COMPRA_FOLLOW_POST, params); },
  postFollowOrderCancel: function (params) { return Auth.post(COMPRA_FOLLOW_CANCEL_POST, params); },
  postCompraATarjeta: function (params) { return Auth.post(COMPRA_CAMBIA_TARJETA_POST, params); },
  postManualPaid: function (params) { return Auth.post(COMPRA_PAGO_MANUAL_POST, params); },
  postGeneratePaymentLink: function (params) { return Auth.post(COMPRA_GENERA_LINK_POST, params); },
  postUsuario: function (params) { return Auth.post(USUARIO_NUEVO_POST, params); },
  getSegmentsLocation: function (location) { return location.split("/"); },
  getRoles: function (params = false) { return Auth.get(GET_ROLES, params); },
  postRol: function (params) { return Auth.post(POST_ROL, params); },
  getPermisos: function (params = false) { return Auth.get(GET_PERMISOS, params); },
  postPermiso: function (params) { return Auth.post(POST_PERMISO, params); },
  savePermisos: function (params) { return Auth.post(POST_PERMISO_CHECK, params); },
  getPermisosRol: function (params) { return Auth.get(GET_PERMISOS_ROL, params); },
  getMenuRol: function (params) { return Auth.get(GET_MENU, params); },
  postMenu: function (params) { return Auth.post(POST_MENU, params); },
  getMenuByRol: function (params) { return Auth.get(GET_MENU_ROL, params); },
  getAllowedRoles: function (params) { return Auth.get(GET_ALLOWED_ROLES, params); },
  saveMenus: function (params) { return Auth.post(POST_MENU_CHECK, params); },
  getRoutes: function (params) { return Auth.get(GET_ROUTES, params); },
  getBreeds: function (params) { return Auth.get(GET_BREEDS, params); },
  getPetProdActive: function (params) { return Auth.get(GET_PETS_PROD_ACTIVE, params); },
  getTypeProduct: function (params) { return Auth.get(GET_TIPO_DE_PRODUCTO, params); },

  ////////////////////////////////////CATALOGO ////////////////////////////////////////////// 

  //************************ GETS **********************************************************/
  getVariationsCatalogo: function (params) { return Auth.get(GET_VARIATIONS_CATALOGO, params); },
  getProductsCatalogue: function (params) { return Auth.get(GET_PRODUCT_CATALOGUE, params); },
  postProductCatalogue: function (params) { return Auth.post(POST_PRODUCT_CATALOGUE, params); },
  getBrandsCatalogue: function (params) { return Auth.get(GET_BRANDS_CATALOGUE, params); },
  getCatagoriesCatalogue: function (params) { return Auth.get(GET_CATEGORIES_CATALOGUE, params); },
  getSpeciesCatalogue: function (params) { return Auth.get(GET_SPECIES_CATALOGUE, params); },
  getAttributesCatalogue: function (params) { return Auth.get(GET_ATTRIBUTES_CATALOGUE, params); },
  getProductVariationCatalogue: function (params) { return Auth.get(GET_PRODUCT_VARIATION_CATALOGUE, params); },
  getProductById: function (params) { return Auth.get(GET_PRODUCT_BY_ID_CATALOGUE, params); },
  getVariation: function (params) { return Auth.get(GET_VARIACION_TEMPLATE, params); },
  getPrvPics: function (params) { return Auth.get(GET_PRV_PICS, params); },
  getFindCombinedProductsByPrv: function (params) { return Auth.get(GET_FIND_COMBINED_PRODUCTS_BY_PRV, params); },
  //************************ END GETS**********************************************************/

  //************************POSTS **********************************************************/
  postVariation: function (params) { return Auth.post(POST_VARIATION, params); },
  postDeleteProduct: function (params) { return Auth.post(POST_DELETE_PROD_CATALOGUE, params); },
  postDeleteProductVariation: function (params) { return Auth.post(POST_DELETE_PROD_VARIATION_CATALOGUE, params); },
  deleteVariationPost: function (params) { return Auth.post(DELETE_VARIATION, params); },
  postMultiplePics: function (params) { return Auth.post(POST_MULTIPLE_PICS, params); },
  //************************END POSTS **********************************************************/

  //////////////////////////////////// END CATALOGO ////////////////////////////////////////////////////

  postVariationTemplates: function (params) { return Auth.post(POST_VARIACION_TEMPLATE, params); },
  postOrderDigitales: function (params) { return Auth.post(POST_ORDER_DIGITALES, params); },
  postSaveVariationTemplates: function (params) { return Auth.post(SEND_VARIATIONS_TEMPLATES, params); },
  getReproggramingReasons: function (params) { return Auth.get(REPROGRAMMING_REASONS_GET, params); },
  getPetshopsForReproggraming: function (params) { return Auth.get(PETSHOPS_FOR_REPROGRAMMING_GET, params); },
  newBalance: function (params) { return Auth.post(POST_NEW_BALANCE, params); },
  getOrderDeliveriedDigitals: function (params) { return Auth.get(ORDERS_DELIVERIED_DIGITALS, params); },
  resolveWithRefundPichoPesos: function (params) { return Auth.post(RESOLVE_PROBLEM_REFUND_PICHO_PESOS, params); },
  resolveWithRefundCard: function (params) { return Auth.post(RESOLVE_PROBLEM_REFUND_CARD, params); },
  resolveWithCompleteTheOrder: function (params) { return Auth.post(RESOLVE_PROBLEM_COMPLETE_ORDER, params); },
  resolveConfusedProducts: function (params) { return Auth.post(RESOLVE_PROBLEM_CONFUSED, params); },
  regenerateTemplates: function (params) { return Auth.post(REGENERATE_TEMPLATES, params); },
  postBlackList: function (params) { return Auth.post(POST_BLACK_LIST, params); },
  postPassOrderToPayed: function (params) { return Auth.post(POST_PASS_ORDER_TO_PAYED, params); },
  postCancelReturnOrder: function (params) { return Auth.post(POST_CANCEL_RETURN_ORDER, params); },
  postInsertMaster: function (params) { return Auth.post(POST_INSERT_MASTER, params); },
  postInsertPetshop: function (params) { return Auth.post(POST_INSERT_PETSHOP, params); },
  postProductVariation: function (params) { return Auth.post(POST_PRODUCT_VARIATION, params); },
  getVariationsForInputSelect: function () { return Auth.get(GET_PRODUCT_VARIATIONS); },
  // CONFIG
  getConfigs: function (params = false) { return Auth.get(GET_CONFIGS, params); },
  postConfig: function (params) { return Auth.post(POST_CONFIG, params); },
  // FINANCES
  getFinancesVentas: function (params = false) { return Auth.get(GET_TOTAL_VENTAS, params); },
  getFacturacionMensual: function (params = false) { return Auth.get(GET_FACTURACION_MENSUAL, params); },
  getProductsPrices: function (params = false) { return Auth.get(GET_PRODUCTS_PRICES, params); },
  getProductsCompraGrafico: function (params = false) { return Auth.get(GET_PRODUCTS_COMPRAS_GRAFICO, params); },
  // REPORTES
  getPurchaseOrderSimulator: function (params = false) { return Auth.get(GET_PURCHASE_ORDERS_SIMULATOR, params); },
  getAllOrderInvoice: function (params = false) { return Auth.get(GET_ALL_ORDERS_INVOICE, params); },
  postSendEmailOrderInvoice: function (params = false) { return Auth.post(POST_SEND_EMAIL_ORDER_INVOICE, params); },
  postCompleteComprobanteNumber: function (params = false) { return Auth.post(POST_COMPLETE_COMPROBANTE_NUMBER, params); },
  getReportIndicadores: function (params = false) { return Auth.get(GET_INDICADORES, params); },
  getReportCanceledOrders: function (params = false) { return Auth.get(GET_CANCELED_ORDERS, params); },
  getReportProblemOrders: function (params = false) { return Auth.get(GET_PROBLEM_ORDERS, params); },
  getReportProductsSold: function (params = false) { return Auth.get(GET_PRODUCTS_SOLD, params); },
  getReportIntervened: function (params = false) { return Auth.get(GET_ORDERS_INTERVENED, params); },
  getReportDelegate: function (params = false) { return Auth.get(GET_ORDERS_DELEGATES, params); },
  getPetshopsDelegations: function (params = false) { return Auth.get(GET_PETSHOPS_DELEGATIONS, params); },
  getPetshopsDelegationsByProducts: function (params = false) { return Auth.get(GET_PETSHOPS_DELEGATIONS_BY_PRODUCTS, params); },
  getIntervenedReasons: function (params = false) { return Auth.get(GET_INTERVENED_REASONS, params); },
  getDelegateReasons: function (params = false) { return Auth.get(GET_DELEGATE_REASONS, params); },
  getErrorCuotas: function (params = false) { return Auth.get(GET_ERROR_INSTALLMENT, params); },
  getOrdersInPolygon: function (params = false) { return Auth.get(GET_ORDERS_IN_POLYGON, params); },
  getProductsInPolygon: function (params = false) { return Auth.get(GET_PRODUCTS_IN_POLYGON, params); },
  getOrdersAtencionCliente: function (params) { return Auth.get(PETSHOP_GET_ORDERS_CLIENT, params); },
  getHeatZone: function (params = false) { return Auth.get(GET_HEATZONE, params); },
  getProductsByPuvBasedOnSales: function (params = false) { return Auth.get(GET_PRODUCTS_BY_PUV_BASED_ON_SALES, params); },
  getPetshopsBySchedule: function (params = false) { return Auth.get(GET_PETSHOPS_HOURS, params); },
  getGeneralOrders: function (params) { return Auth.get(GET_GENERAL_ORDERS, params); },
  getReprogramedOrders: function (params = false) { return Auth.get(GET_REPROGRAMED_ORDERS, params); },
  getReprogramedReasons: function (params = false) { return Auth.get(GET_REPROGRAMED_REASONS, params); },
  getMisPichosOrders: function (params = false) { return Auth.get(GET_MISPICHOS_ORDERS, params); },
  getMisPichosOrdersIndicators: function (params = false) { return Auth.get(GET_MISPICHOS_ORDERS_INDICATORS, params); },
  getCompleteManualOrders: function (params = false) { return Auth.get(GET_COMPLETE_ORDERS_MANUAL, params); },
  getUsersWithPaymentProblems: function (params = false) { return Auth.get(GET_USERS_WITH_PAYMENT_PROBLEMS, params); },
  getReportSuscripciones: function (params = false) { return Auth.get(GET_SUSCRIPTIONS_REPORT, params); },
  getReportDeletedProdPostEdit: function (params = false) { return Auth.get(DELETED_PROD_POST_EDIT, params); },
  getReportEditionBalance: function (params = false) { return Auth.get(GET_REPORT_EDITION_BALANCE, params); },
  getAssignmentProcess: function (params = false) { return Auth.get(GET_ASSIGNMENT_PROCESS, params); },
  getReportPetshopCreateUpdate: function (params = false) { return Auth.get(GET_CREATE_UPDATE_PETSHOP_REPORT, params); },
  getOutOfHoursDelivery: function (params = false) { return Auth.get(GET_OUT_OF_HOURS_REPORT, params); },
  getContactedByCs: function (params = false) { return Auth.get(GET_REPORT_CONTACTED_CS, params); },
  getReportCupones: function (params) { return Auth.get(GET_REPORT_CUPONES, params); },
  getRescheduleOrders: function (params) { return Auth.get(GET_REPORT_RESCHEDULE_ORDERS, params); },
  getAllCuponesList: function (params = false) { return Auth.get(GET_ALL_CUPONES, params); },
  getReportFacturacionMensual: function (params = false) { return Auth.get(GET_REPORT_FACT_MENSUAL, params); },
  getReporteSujetosSicore: function (params = false) { return Auth.get(GET_REPORT_SUJETOS_SICORE, params); },
  getReporteDiferenciaStock: function (params = false) { return Auth.get(GET_REPORT_DFERENCIA_STOCK, params); },
  getReportePercepcionSicore: function (params = false) { return Auth.get(GET_REPORT_PERCEPCION_SICORE, params); },
  getReportFabricaPaga: function (params = false) { return Auth.get(GET_REPORT_FABRICA_PAGA, params); },
  getReportComisionesDuplicadas: function (params = false) { return Auth.get(GET_REPORT_COMISION_DUPLICADA, params); },
  getCuotasSinInteres: function (params = false) { return Auth.get(GET_REPORT_CUOTAS_SIN_INTERES, params); },
  getOperativoControlOrders: function (params = false) { return Auth.get(GET_REPORT_OPERATIVE_CONTROL_ORDERS, params); },
  getProdManualAssingment: function (params = false) { return Auth.get(PROD_MANUAL_ASSINGMENT, params); },
  getBlackList: function (params = false) { return Auth.get(GET_BLACK_LIST, params); },
  getReportIngresosPetshops: function (params = false) { return Auth.get(GET_REPORT_INGRESOS_PETSHOPS, params); },
  getTypeReport: function (params = false) { return Auth.get(GET_TYPE_REPORT, params); },
  getReportControlDevoluciones: function (params = false) { return Auth.get(GET_REPORT_CONTROL_DEVOL, params); },
  getReportViajesColecta: function (params = false) { return Auth.get(GET_REPORT_VIAJES_COLECTA, params); },
  getImagesReportViajes: function (params = false) { return Auth.get(GET_IMAGES_REPORT_VIAJES_COLECTA, params); },
  getReportProductosVendidos: function (params = false) { return Auth.get(GET_REPORT_PRODUCTOS_VENDIDOS_CANTIDAD, params); },
  getReturnAndRetireOrders: function (params = false) { return Auth.get(GET_REPORT_RETURN_AND_RETIRE_ORDERS, params); },
  getSolutionOrders: function (params = false) { return Auth.get(GET_REPORT_SOLUTION_ORDERS, params); },
  getReportCanceledByGMVDetailed: function (params = false) { return Auth.get(GET_REPORT_CANCELED_BY_GMV_DETAILED, params); },
  getReportCanceledByGMVGrouped: function (params = false) { return Auth.get(GET_REPORT_CANCELED_BY_GMV_GROUPED, params); },
  getReportCanceledByGMVUserList: function (params = false) { return Auth.get(GET_USERLIST_REPORT_CANCELED_BY_GMV, params); },
  getReportConciliadorPrisma: function (params = false) { return Auth.get(GET_REPORT_CONCILIADOR_PRISMA, params); },
  getPdteActivarDesactivar: function (params) { return Auth.get(GET_PDTE_ACTIVAR_DESACTIVAR, params); },
  getPetshopsDeliveryEfficiency: function (params) { return Auth.get(GET_PETSHOPS_DELIVERY_EFFICIENCY, params); },

  // SUSCRIPTIONS
  getReportPedidosLogistica: function (params = false) { return Auth.get(GET_REPORT_PEDIDOS_LOGISTICA, params); },
  getMovCuentaCorriente: function (params = false) { return Auth.get(GET_MOV_CUENTA_CORRIENTE, params); },


  // SUSCRIPTIONS
  getSuscriptions: function (params = false) { return Auth.get(GET_SUSCRIPTIONS, params); },
  getSuscriptionMovements: function (params = false) { return Auth.get(GET_SUSCRIPTION_MOVEMENTS, params); },
  processPaymentSuscription: function (params = false) { return Auth.get(PROCESS_PAYMENT_SUSCRIPTION, params); },
  noticePaymentSuscriptions: function (params = false) { return Auth.get(NOTICE_PAYMENT_SUSCRIPTIONS, params); },
  noticeCardExpired: function (params = false) { return Auth.get(NOTICE_CARDS_EXPIRED, params); },
  paymentMovement: function (params) { return Auth.post(PAYMENT_MOVEMENT, params); },
  checkPaymentSuscription: function (params = false) { return Auth.post(CHECK_PAYMENT_SUSCRIPTION, params); },
  addPolicySuscription: function (params = false) { return Auth.post(ADD_POLICY_SUSCRIPTION, params); },
  unsubscribeSuscriptions: function (params) { return Auth.post(POST_UNSUBSCRIBE_SUSCRIPTIONS, params); },
  getSuscriptionsColon: function (params = false) { return Auth.get(GET_SUSCRIPTONS_COLON, params); },
  getRefundsRequested: function (params = false) { return Auth.get(GET_REFUNDS_REQUESTED, params); },
  getRefundsStatusBar: function (params = false) { return Auth.get(GET_REFUNDS_INDICATORS, params); },
  sendRefundInsurranceCarrier: function (params) { return Auth.post(SEND_REFUNDS_INSURRANCE_CARRIER, params); },
  sendFilesRefund: function (params) { return Auth.post(SEND_REFUNDS_FILES, params); },
  getUnSubscribeReason: function (params = false) { return Auth.get(GET_UN_SUSCRIPTONS_REASON, params); },
  getSuscripcionIndicators: function (params = false) { return Auth.get(GET_SUSCRIPTONS_Indicators, params); },
  postReSendDataSuscription: function (params) { return Auth.post(RESEND_DATA_SUSCRIPTION, params); },

  //LOGISTICA
  getZoneDelivery: function (params) { return Auth.get(GET_ZONE_DELIVERY, params); },
  getDeliveryTimes: function (params) { return Auth.get(GET_DELIVERY_TIME, params); },
  getPetshopDeliveryZone: function (params) { return Auth.get(GET_PETSHOPS_DELIVERY_ZONE, params); },
  saveZoneDelivery: function (params) { return Auth.post(POST_ZONE_DELIVERY, params); },
  savePetshopDeliveryZone: function (params) { return Auth.post(POST_PETSHOP_DELIVERY_ZONE, params); },
  getOrdersSpecialZone: function (params) { return Auth.get(ORDERS_SPECIAL_ZONE, params); },
  petshopLogisticCapacity: function (params) { return Auth.post(PETSHOP_LOGISTIC_CAPACITY, params); },
  getBrandsGeneral: function (params) { return Auth.get(GET_BRANDS_GENERAL, params); },
  getZonesFiltersAll: function (params) { return Auth.get(GET_ZONES_FILTERS_ALL, params); },
  getCategoryGeneral: function (params) { return Auth.get(GET_CATEGORY_GENERAL, params); },
  getPetshopsPolygons: function (params) { return Auth.get(GET_PETSHOPS_POLYGONS, params); },
  getPetshopsCapacity: function (params) { return Auth.get(GET_PETSHOPS_CAPACITY, params); },
  getlogisticCapacityUsedAndConfigs: function (params) { return Auth.get(GET_LOGISTIC_CAPACITY_USED_AND_CONFIGS, params); },
  getlogisticCapacityUsedBySpecialZoneWithCapacities: function (params) { return Auth.get(GET_LOGISTIC_CAPACITY_USED_AND_CONFIGS_SPECIAL, params); },
  getLogisticList: function (params) { return Auth.get(GET_LOGISTIC_LIST, params); },
  getUsersLogistic: function (params) { return Auth.get(GET_USERS_LOGISTIC, params); },
  postTransporterWithLogistic: function (params) { return Auth.post(POST_TRANSPORTERS_WITH_LOGISTIC, params); },
  getPackedOrdersWithStatus: function (params) { return Auth.get(GET_STATUS_ORDERS_PACKED, params); },
  postLogisticEnterprise: function (params) { return Auth.post(POST_LOGISTIC_LIST, params); },

  OurSourcingDelivery: {
    postShippingsFromOrders: function (params) { return Auth.post(POST_SHIPPINGS_FROM_ORDERS, params); },
    getOrdersAdmin: function (params) { return Auth.get(GET_OUTSOURCE_DELIVERY_ADMIN_ORDERS, params); },
    getAgreements: function (params) { return Auth.get(GET_OUTSOURCE_AGREEMENTS, params); },
    getTransportersByAgreement: function (params) { return Auth.get(GET_OUTSOURCE_TRANSPORTERS_BY_AGREEMENT, params); },
    getSchedulesActiveOutsourcing: function (params) { return Auth.get(GET_OUTSOURCING_SCHEDULES_ACTIVES, params); },
    countOrdersPet: function (params) { return Auth.get(GET_OUTSOURCING_COUNT_ORDERS, params); },
    getReportViajes: function (params) { return Auth.get(GET_OUTSOURCING_VIAJES_REPORT, params); },
    getTransporterFilterLogistic: function (params) { return Auth.get(GET_OUTSOURCING_TRANSPORTER_FILTER, params); },
  },
  getLogisticVariations: function (params) { return Auth.get(GET_VARIACIONES_LOGISTICA, params); },
  postLogisticVariations: function (params) { return Auth.post(POST_VARIACIONES_LOGISTICA, params); },


  //DISCOUNT
  getDiscountList: function (params = false) { return Auth.get(GET_DISCOUNT, params); },
  discountSave: function (params = false) { return Auth.post(SAVE_DISCOUNT, params); },
  geRreferredActive: function (params) { return Auth.get(GET_REFERRED_ACTIVE, params); },
  changeStatusDiscount: function (params) { return Auth.post(CHANGE_DISCOUNT_STATUS, params); },

  //ATENCION CLIENTE
  postCategory: function (params) { return Auth.post(POST_CATEGORY, params); },
  deleteCategory: function (params) { return Auth.post(DELETE_CATEGORY, params); },
};

//ROUTES API CLIENT
const CLIENT_ENDPOINT = "client_controller/";
const CLIENT_HOME_BRANDS_GET = CLIENT_ENDPOINT + "brands/";

//LOGIN API MARKETING MANAGER
const LOGIN_ENDPOINT = "login_controller/";
const GET_ROUTES = LOGIN_ENDPOINT + "routes/";

//ROUTES API MARKETING MANAGER
const MARKETING_MANAGER_ENDPOINT = "marketingapi_controller/";
const MARKETING_GET_LANDING = MARKETING_MANAGER_ENDPOINT + "landing/";
const MARKETING_GET_LANDINGS = MARKETING_MANAGER_ENDPOINT + "landings/";
const MARKETING_EDIT_LANDING = MARKETING_MANAGER_ENDPOINT + "editLanding/";
const MARKETING_NEW_LANDING = MARKETING_MANAGER_ENDPOINT + "newLanding/";

//ROUTES API ACCOUNT MANAGER
const ACCOUNT_MANAGER_ENDPOINT = "accountmanagerapi_controller/";
const ACCOUNT_MANAGER_PETSHOPLIST_GET = ACCOUNT_MANAGER_ENDPOINT + "petshops/";
const ACCOUNT_MANAGER_TRANSFERS_GET = ACCOUNT_MANAGER_ENDPOINT + "transfers/";
const ACCOUNT_MANAGER_BBVA_TXT_GET = ACCOUNT_MANAGER_ENDPOINT + "transfersTxtBBVA/";
const ACCOUNT_MANAGER_ORDER_NO_COMISSION_POST = ACCOUNT_MANAGER_ENDPOINT + "noComissionOrder/";
const ACCOUNT_MANAGER_TRANSFER_MONEY_POST = ACCOUNT_MANAGER_ENDPOINT + "transferMoney/";
const ACCOUNT_MANAGER_TRANSFER_MONEY_PRISMA_POST = ACCOUNT_MANAGER_ENDPOINT + "transferMoneyPrisma/";
const ACCOUNT_ALLOW_CHANGE_DATA_POST = ACCOUNT_MANAGER_ENDPOINT + "allowChangeData/";
const ACCOUNT_CASH_ROW_POST = ACCOUNT_MANAGER_ENDPOINT + "cashRow/";
const ACCOUNT_GET_XUBIO_CLIENTS = ACCOUNT_MANAGER_ENDPOINT + "xubioClients/";
const ACCOUNT_PAUSE_POST = ACCOUNT_MANAGER_ENDPOINT + "pausePetshop/";
const ACCOUNT_INACTIVE_POST = ACCOUNT_MANAGER_ENDPOINT + "inactivePetshop/";
const ACCOUNT_GET_PETSHOP_PROFILE_GET = ACCOUNT_MANAGER_ENDPOINT + "petshopProfile/";
const ACCOUNT_CHANGE_DATE_CALCULATE_INDX_POST = ACCOUNT_MANAGER_ENDPOINT + "changeDateToCalculateIndex/";
const ACCOUNT_MANAGER_INVOICES_GET = ACCOUNT_MANAGER_ENDPOINT + "invoices/";
const ACCOUNT_MANAGER_INVOICES_INCOME_GET = ACCOUNT_MANAGER_ENDPOINT + "invoicesIncome/";
const ACCOUNT_INVOICE_CREATE = ACCOUNT_MANAGER_ENDPOINT + "invoice/";
const ACCOUNT_INVOICE_INCOME_CREATE = ACCOUNT_MANAGER_ENDPOINT + "invoiceIncome/";
const ACCOUNT_CREATE_ALL_INVOICE = ACCOUNT_MANAGER_ENDPOINT + "createAllInvoice/";
const ACCOUNT_CREATE_ALL_INVOICE_INCOME = ACCOUNT_MANAGER_ENDPOINT + "createAllInvoiceIncome/";
const SEND_PET_CATEGORY = ACCOUNT_MANAGER_ENDPOINT + "setCategory/";
const SEND_ACTIVE_AUTO_ACEPT = ACCOUNT_MANAGER_ENDPOINT + "setPuedeAutoAcept/";
const CHECK_NO_CAE_GENERATED = ACCOUNT_MANAGER_ENDPOINT + "checkNoCaeGenerated/";


//ROUTES ACCOUNTABILITY
const ACCOUNTABILITY_ENDPOINT = "accountabilityapi_controller/";
const ACCOUNTABILITY_GET_CLIENT_PAYMENT = ACCOUNTABILITY_ENDPOINT + "clientPayment/";
const ACCOUNTABILITY_POST_ORDER_SUSPICIUS = ACCOUNTABILITY_ENDPOINT + "orderSuspicius/";
const ACCOUNTABILITY_GET_DASHBOARD = ACCOUNTABILITY_ENDPOINT + "dashboard/";

//ROUTES INVENTORY API
const INVENTORY_ENDPOINT = "inventory_controller/";
const PETSHOP_GET_INVENTORY_MOVEMENTS = INVENTORY_ENDPOINT + "inventoryMovements/";
const PETSHOP_GET_INDICATORS_DATA = INVENTORY_ENDPOINT + "findInventoryMovements/";
const PETSHOP_GET_DELIVERY_NOTES = INVENTORY_ENDPOINT + "deliveryNote/";
const PETSHOP_GET_DELIVERY_NOTES_INVENTORY = INVENTORY_ENDPOINT + "deliveryNoteInventory/";
const PETSHOP_GET_INVENTORY_MOTIVES = INVENTORY_ENDPOINT + "inventoryMotives/";
const PETSHOP_POST_INFLOW = INVENTORY_ENDPOINT + "inflow/";
const PETSHOP_POST_OUTFLOW = INVENTORY_ENDPOINT + "outflow/";
const PETSHOP_SAVE_IMAGE_BY_DELIVERY_NOTE = INVENTORY_ENDPOINT + "saveImageByInventory/";
const PETSHOP_GET_BRAND_PROVIDERS = INVENTORY_ENDPOINT + "brandProviders/";
const PETSHOP_GET_PRODUCT_BY_CODE = INVENTORY_ENDPOINT + "searchProdByCode/";
const PETSHOP_GET_RESERVED_PRODUCTS = INVENTORY_ENDPOINT + "inventoryMovementsIndicators/";
const SKU_BULK_LOAD = INVENTORY_ENDPOINT + "skuBulkLoad/";
const CHECK_USR_ACTION_IDENTIFIER = INVENTORY_ENDPOINT + "checkUsrActionIdentifier/";

//ROUTES API PETSHOP
const PETSHOP_ENDPOINT = "proveedorapi_controller/";
const PETSHOP_GET_XUBIO_CLIENTS = PETSHOP_ENDPOINT + "xubioClient/";
const PETSHOP_GET_DASHBOARD = PETSHOP_ENDPOINT + "indicadoresDashboard/";
const PETSHOP_GET_ORDERS = PETSHOP_ENDPOINT + "orders/";
const PETSHOP_GET_MY_ORDERS = PETSHOP_ENDPOINT + "myOrders/";
const PETSHOP_GET_PREPARATION_ORDERS = PETSHOP_ENDPOINT + "preparationOrders/";
const PETSHOP_GET_PRE_PICKING_INFO = PETSHOP_ENDPOINT + "prePickingInformation/";
const PETSHOP_POST_INFORM_PRINT = PETSHOP_ENDPOINT + "informPrint/";
const PETSHOP_GET_PREPARATION_ORDERS_INDICATORS = PETSHOP_ENDPOINT + "preparationOrdersIndicators/";
const PETSHOP_GET_ORDER_BY_ID = PETSHOP_ENDPOINT + "order/";
const PETSHOP_GET_NEWS = PETSHOP_ENDPOINT + "news/";
const PETSHOP_CONSULTA_TELEFONO_GET = PETSHOP_ENDPOINT + "phoneOrder/";
const PETSHOP_COMPRA_CONSULTA_STOCK_GET = PETSHOP_ENDPOINT + "consultaStock/";
const PETSHOP_COMPRA_CONSULTA_STOCK_POST = PETSHOP_ENDPOINT + "altaConsultaStock/";
const PETSHOP_COMPRA_CONSULTA_ENTREGA_POST = PETSHOP_ENDPOINT + "altaConsultaEntrega/";
const PETSHOP_COMPRA_CONSULTA_ENTREGA_GET = PETSHOP_ENDPOINT + "consultaEntrega/";
const PETSHOP_COMPRA_DELEGAR_POST = PETSHOP_ENDPOINT + 'delegate/';
const PETSHOP_COMPRA_ACEPTAR_POST = PETSHOP_ENDPOINT + 'preparingorder/';
const PETSHOP_COMPRA_ENTREGADO_POST = PETSHOP_ENDPOINT + 'deliveredorder/';
const PETSHOP_COMPRA_PROBLEMA_POST = PETSHOP_ENDPOINT + 'problemorder/';
const PETSHOP_PAYING_WEEKLY_GET = PETSHOP_ENDPOINT + 'getLiquidacionSemanal/';
const PETSHOP_PAYING_MONTHLY_GET = PETSHOP_ENDPOINT + 'getLiquidacionMensual/';
const PETSHOP_SPECIAL_DAYS_GET = PETSHOP_ENDPOINT + 'specialDays/';
const PETSHOP_SPECIAL_DAYS_POST = PETSHOP_ENDPOINT + 'specialDays/';
const PETSHOP_POLIGONO_POST = PETSHOP_ENDPOINT + 'polygon/';
const PETSHOP_POLIGONO_GET = PETSHOP_ENDPOINT + 'polygon/';
const PETSHOP_PRODUCTOS_GET = PETSHOP_ENDPOINT + 'products/';
const PETSHOP_PRODUCTOS_POST = PETSHOP_ENDPOINT + 'products/';
const PETSHOP_SCHEDULE_POST = PETSHOP_ENDPOINT + 'schedule/';
const PETSHOP_SCHEDULE_GET = PETSHOP_ENDPOINT + 'schedule/';
const PETSHOP_PROFILE_POST = PETSHOP_ENDPOINT + 'profile/';
const PETSHOP_PROFILE_GET = PETSHOP_ENDPOINT + 'profile/';
const PETSHOP_ADDRESS_POST = PETSHOP_ENDPOINT + 'address/';
const PETSHOP_COMPRA_VALIDATE_POST = PETSHOP_ENDPOINT + 'validateOrder/';
const PETSHOP_NOTIFICATION_VIEWED_POST = PETSHOP_ENDPOINT + 'notification/';
const PETSHOP_NOTIFICATION_SURVEY_VIEWED_POST = PETSHOP_ENDPOINT + 'notiSurvey/';
const REPORTE_LIQUIDACION_GET = PETSHOP_ENDPOINT + 'reporte/';
const REPORTE_COMISIONES_GET = PETSHOP_ENDPOINT + 'reporteComision/';
const REPORTE_DESCUENTOS_GET = PETSHOP_ENDPOINT + 'reporteDescuento/';
const PETSHOP_GET_REPORT_STATICS = PETSHOP_ENDPOINT + 'reporteAutoGestion/';
const PETSHOP_ACCOUNT_REGISTRY_GET = PETSHOP_ENDPOINT + 'accountRegistry/';
const PETSHOP_DELEGATE_PREPARING_ORDER_POST = PETSHOP_ENDPOINT + 'delegatePreparingOrder/';
const PETSHOP_MEGA_BUSCADOR_GET = PETSHOP_ENDPOINT + 'megaSearch/';
const GET_TRANSPORTERS = PETSHOP_ENDPOINT + 'transporters/';
const POST_TRANSPORTERS = PETSHOP_ENDPOINT + 'transporter/';
const PETSHOP_INVOICE_EMAIL_POST = PETSHOP_ENDPOINT + 'sendInvoiceByEmail/';
const PETSHOP_NOTIFICATION_HISTORIAL_GET = PETSHOP_ENDPOINT + 'centralNotifications/';
const PETSHOP_CONSOLE_OPEN_POST = PETSHOP_ENDPOINT + 'inspectorElementOpened/';
const UPDATE_SHOW_LABEL_MOOVA = PETSHOP_ENDPOINT + 'updateshowlabel/';
const PETSHOP_GET_REPORT_SOLD_PRODUCTS = PETSHOP_ENDPOINT + 'productosVendidos/';
const PETSHOP_PRODUCTS_CATEGORY = PETSHOP_ENDPOINT + 'productsCategory/';
const PETSHOP_PRODUCTS_CATEGORY_BRANDS = PETSHOP_ENDPOINT + 'productsCategoryByBrands/';
const PETSHOP_AUTO_ACEPT_ORDERS = PETSHOP_ENDPOINT + 'autoAcept/';
const PETSHOP_TRANSPORTERS_MANUAL_ASSIGNATION = PETSHOP_ENDPOINT + 'transpotersManualAssignation/';
const PETSHOP_TRANSPORTERS_ASSIGN_ORDERS = PETSHOP_ENDPOINT + 'transpotersAssignOrders/';
const PETSHOP_TRANSPORTERS_REMOVE_ASSIGNED_ORDERS = PETSHOP_ENDPOINT + 'removeTranspoterFromOrders/';
const PETSHOP_TRANSPORTERS_RESET_TRANSPORTER_ORDERS = PETSHOP_ENDPOINT + 'resetTransporterOrders/';
const PICK_UP_ORDERS = PETSHOP_ENDPOINT + 'trackingOfOutsourcedLogistics/';
const PETSHOP_SCHEDULES_ACTIVES = PETSHOP_ENDPOINT + "schedulesActives/";
const PETSHOP_GET_NOTES = PETSHOP_ENDPOINT + "extraNotes/";
const PETSHOP_GET_CLIENT_PAYMENT = PETSHOP_ENDPOINT + "clientPayment/";
const PETSHOP_GET_RETURN_ORDERS = PETSHOP_ENDPOINT + "returnOrders/";
const PETSHOP_POST_RESOLVE_RETURN_ORDERS = PETSHOP_ENDPOINT + "resolveReturnOrder/";
const GET_PETSHOP_DELIVERY_ORDERS = PETSHOP_ENDPOINT + "ordersPetshopLogistic/";
const GET_PETSHOP_DELIVERY_ORDERS_INDIC = PETSHOP_ENDPOINT + "ordersPetshopLogisticIndicators/";
const GET_PETSHOP_LOGISTIC_TRANSPORTER = PETSHOP_ENDPOINT + "transportersByPetshopLogistic/";
const GET_MISSING_DELIVERY_TIME = PETSHOP_ENDPOINT + "missingDeliveryTime/";
const PETSHOP_POST_FINISH_PACKED = PETSHOP_ENDPOINT + "finishPacking/";
const GET_ORDERS_LOGISTIC_ASSIGNMENT = PETSHOP_ENDPOINT + "ordersLogisticAssignment/";
const PETSHOP_POST_PRINT_COMPROBANT = PETSHOP_ENDPOINT + "printComprobantAndMovement/";
const PETSHOP_POST_PRINT_COMPROBANT_MASSIVE = PETSHOP_ENDPOINT + "printComprobantAndMovementMassive/";
const PETSHOP_GET_ZONES_FILTERS = PETSHOP_ENDPOINT + "zonesFilter/";

//ROUTES API TRANSPORTER
const TRANSPORTER_ENDPOINT = "transporterapi_controller/";
const TRANSPORTER_GET_ORDERS = TRANSPORTER_ENDPOINT + "orders/";
const TRANSPORTER_START_DELIVERY_POST = TRANSPORTER_ENDPOINT + "deliveryStart/";
const TRANSPORTER_COMPLETE_DELIVERY_POST = TRANSPORTER_ENDPOINT + "deliveryComplete/";
const TRANSPORTER_DELEGATE_ACCEPTED_ORDER = TRANSPORTER_ENDPOINT + "delegateAcceptedOrder/";
const TRANSPORTER_GET_CONSULT_STOCK = TRANSPORTER_ENDPOINT + "consultStock/";

//ROUTES API LANDING
const LANDING_ENDPOINT = "landing_controller/";
const LANDING_GET = LANDING_ENDPOINT + "landing/";
const LANDING_SAVE = LANDING_ENDPOINT + "save/";
const LANDING_ACTIVE_POST = LANDING_ENDPOINT + "active/";
const LANDING_INACTIVE_POST = LANDING_ENDPOINT + "inactive/";


// PRODUCT CATALOGUE ENDPOINT
const PRODUCT_CATALOGUE_ENDPOINT = "product_controller/";
const GET_PRODUCT_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "products/";
const GET_BRANDS_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "brands/";
const GET_CATEGORIES_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "categories/";
const GET_SPECIES_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "species/";
const GET_ATTRIBUTES_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "attributes/";
const GET_PRODUCT_VARIATION_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "prv/";
const GET_PRODUCT_VARIATIONS = PRODUCT_CATALOGUE_ENDPOINT + "variations/";
const GET_PRODUCT_BY_ID_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "product/";
const GET_PRV_PICS = PRODUCT_CATALOGUE_ENDPOINT + "getPrvPics/";
const POST_PRODUCT_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "product/";
const POST_INSERT_MASTER = PRODUCT_CATALOGUE_ENDPOINT + "insertMasterPet/";
const POST_INSERT_PETSHOP = PRODUCT_CATALOGUE_ENDPOINT + "insertPetshop/";
const POST_PRODUCT_VARIATION = PRODUCT_CATALOGUE_ENDPOINT + "prv/";
const GET_FIND_COMBINED_PRODUCTS_BY_PRV = PRODUCT_CATALOGUE_ENDPOINT + "FindCombinedProductsByPrv/";
const POST_DELETE_PROD_VARIATION_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "deletePrv/";
const POST_DELETE_PROD_CATALOGUE = PRODUCT_CATALOGUE_ENDPOINT + "productStatus/";
const POST_MULTIPLE_PICS = PRODUCT_CATALOGUE_ENDPOINT + "processMultiPrvPic/";


//ROUTES API ATENCION CLIENTE
const ATENCION_CLIENTE_ENDPOINT = "atencioncliente_controller/";
const PANEL_GET_INDICADORES = ATENCION_CLIENTE_ENDPOINT + "panel/";
const CATEGORIA_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "categorias/";
const ATTRIBUTES_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "attributes/";
const ATTRIBUTES_POST = ATENCION_CLIENTE_ENDPOINT + "addOrModAttributes/";
const ATTRIBUTES_DELETE = ATENCION_CLIENTE_ENDPOINT + "deleteAttribute/";
const TYPE_ATTRIBUTES_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "typeattributes/";
const TYPE_ATTRIBUTES_POST = ATENCION_CLIENTE_ENDPOINT + "addOrModTypeAttributes/";
const TYPE_ATTRIBUTES_DELETE = ATENCION_CLIENTE_ENDPOINT + "deleteTypeAttribute/";
const FINISH_COMMERCIAL_EVENT = ATENCION_CLIENTE_ENDPOINT + "finishCommercialEvent/";
const FINISH_COLOR_COMMERCIAL_EVENT = ATENCION_CLIENTE_ENDPOINT + "finishColorCommercialEvent/";
const CLEAR_BRAND_FOOTER_COLOR = ATENCION_CLIENTE_ENDPOINT + "clearBrandFooterColor/";
const SET_COMMERCIAL_EVENT = ATENCION_CLIENTE_ENDPOINT + "setCommercialEvent/";
const SET_BRAND_FOOTER_COLOR = ATENCION_CLIENTE_ENDPOINT + "setBrandFooterColor/";
const MARCAS_WITH_CUCARDAS = ATENCION_CLIENTE_ENDPOINT + "getBrandsInCommercialEvent/";
const MARCAS_WITH_COLORS = ATENCION_CLIENTE_ENDPOINT + "getCustomColorBrands/";
const GET_CUCARDA = ATENCION_CLIENTE_ENDPOINT + "getCommercialEventImage/";
const MARCAS_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "marcas/";
const MARCAS_ABM_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "marcasAbm/";
const MARCA_POST = ATENCION_CLIENTE_ENDPOINT + "marca/";
const MARCA_DELETE = ATENCION_CLIENTE_ENDPOINT + "deleteMarca/";
const ANIMALES_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "animales/";
const COMPRA_GET_ONE = ATENCION_CLIENTE_ENDPOINT + "compraById/";
const COMPRA_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "compras/";
const COMPRA_SPLIT_PRODUCTOS_GET = ATENCION_CLIENTE_ENDPOINT + "compraSplitProductos/";
const COMPRA_CAMBIA_TARJETA_POST = ATENCION_CLIENTE_ENDPOINT + "compraCambiaTarjeta/";
const COMPRA_PAGO_MANUAL_POST = ATENCION_CLIENTE_ENDPOINT + "compraPagoManual/";
const COMPRA_GENERA_LINK_POST = ATENCION_CLIENTE_ENDPOINT + "generatePaymentLink/";
const COMPRA_CANCELA_POST = ATENCION_CLIENTE_ENDPOINT + "cancelarCompra/";
const COMPRA_POST_SPLIT = ATENCION_CLIENTE_ENDPOINT + "compraSplit/";
const COMPRA_POST_COMPLETADA = ATENCION_CLIENTE_ENDPOINT + "compraCompletada/";
const COMPRA_NOTAS_POST = ATENCION_CLIENTE_ENDPOINT + "editaNota/";
const COMPRA_INTERVENCION_POST = ATENCION_CLIENTE_ENDPOINT + "intervencion/";
const COMPRA_MOVIMIENTOS_GET = ATENCION_CLIENTE_ENDPOINT + "movimientos/";
const COMPRA_DOMICILIO_POST = ATENCION_CLIENTE_ENDPOINT + "cambiaDomicilio/";
const COMPRA_CHECK_DOMICILIO = ATENCION_CLIENTE_ENDPOINT + "checkIfAreValidDeliveryDates/";
const USUARIO_GET_ONE = ATENCION_CLIENTE_ENDPOINT + "usuarioById/";
const USUARIO_SEARCH_GET = ATENCION_CLIENTE_ENDPOINT + "userSearch/";
const USUARIO_PERFIL_POST = ATENCION_CLIENTE_ENDPOINT + "editaCliente/";
const USUARIO_GET_DOMICILIOS = ATENCION_CLIENTE_ENDPOINT + "domiciliosPerfil/";
const USUARIO_POST_DOMICILIO = ATENCION_CLIENTE_ENDPOINT + "altaDomicilio/";
const USUARIO_GET_COMPRAS = ATENCION_CLIENTE_ENDPOINT + "comprasPerfil/";
const USUARIO_NUEVO_POST = ATENCION_CLIENTE_ENDPOINT + "userSave/";
const USUARIO_LOGIN_AS_POST = ATENCION_CLIENTE_ENDPOINT + "loginAs/";
const USUARIOS_LIST_GET = ATENCION_CLIENTE_ENDPOINT + "users/";
const REPROGRAMACION_GET_HORARIOS = ATENCION_CLIENTE_ENDPOINT + "fechasYhoras/";
const REPROGRAMACION_POST = ATENCION_CLIENTE_ENDPOINT + "reprogramaPedido/";
const REPROGRAMMING_REASONS_GET = ATENCION_CLIENTE_ENDPOINT + "reprogrammingReasons/";
const PETSHOPS_FOR_REPROGRAMMING_GET = ATENCION_CLIENTE_ENDPOINT + "getPetshopsForReprogramming/";
const ORDERS_DELIVERIED_DIGITALS = ATENCION_CLIENTE_ENDPOINT + "getDeliverySuccessDigitals/";
const RESOLVE_PROBLEM_REFUND_PICHO_PESOS = ATENCION_CLIENTE_ENDPOINT + "resolveProblemWithRefundPichoPesos/";
const RESOLVE_PROBLEM_REFUND_CARD = ATENCION_CLIENTE_ENDPOINT + "resolveProblemWithRefundCard/";
const RESOLVE_PROBLEM_COMPLETE_ORDER = ATENCION_CLIENTE_ENDPOINT + "resolveProblemWithCompleteOrder/";
const RESOLVE_PROBLEM_CONFUSED = ATENCION_CLIENTE_ENDPOINT + "resolveConfusedProblem/";
const COMBINED_PRODUCT_POST = ATENCION_CLIENTE_ENDPOINT + "combinedProduct/";
const CHANGE_STATUS_COMBINED_PRODUCT_POST = ATENCION_CLIENTE_ENDPOINT + "changeStatusCombinedProduct/";
const DELETE_COMBINED_PRODUCT_POST = ATENCION_CLIENTE_ENDPOINT + "deleteCombinedProduct/";
const COMBINED_PRODUCT_GET = ATENCION_CLIENTE_ENDPOINT + "combinedProduct/";
const GET_SLIDERS = ATENCION_CLIENTE_ENDPOINT + "getSliders/";
const POST_SLIDERS = ATENCION_CLIENTE_ENDPOINT + "postSliders/";
const DELETE_LOGIC_SLIDERS = ATENCION_CLIENTE_ENDPOINT + "deleteLogicSlider/";
const POST_SFOTO_DATA = ATENCION_CLIENTE_ENDPOINT + "postSfotoData/";
const POST_ADDITIONAL_PICS = ATENCION_CLIENTE_ENDPOINT + "postCopPics/";
const REGENERATE_TEMPLATES = ATENCION_CLIENTE_ENDPOINT + "regenerateTemplates/";
const GET_TRANSPORTERS_FILTER = ATENCION_CLIENTE_ENDPOINT + "transportersFilter/";
const GET_TRANSPORTERS_LIST = ATENCION_CLIENTE_ENDPOINT + "transporterList/";
const GET_LOGISTIC_LIST = ATENCION_CLIENTE_ENDPOINT + "logisticList/";
const GET_USERS_LOGISTIC = ATENCION_CLIENTE_ENDPOINT + "usersLogistic/";
const POST_LOGISTIC_LIST = ATENCION_CLIENTE_ENDPOINT + "logisticList/";
const GET_LOGISTICS_WITH_TRANSPORTERS_LIST = ATENCION_CLIENTE_ENDPOINT + "logisticWithTransporters/";
const GET_PERMITTED_LOGISTICS = ATENCION_CLIENTE_ENDPOINT + "logisticListToPermitLinkWithTransporter/";
const POST_TRANSPORTERS_WITH_LOGISTIC = ATENCION_CLIENTE_ENDPOINT + 'postTransporterToLogistic/';
const SCHEDULE_GET_ADMIN = ATENCION_CLIENTE_ENDPOINT + 'scheduleAdmin/';
const SCHEDULE_POST_ADMIN = ATENCION_CLIENTE_ENDPOINT + 'scheduleAdminPost/';
const ATENCION_GET_PETSHOP_DELIVERY_ORDERS = ATENCION_CLIENTE_ENDPOINT + "ordersPetshopLogistic/";
const ATENCION_GET_PETSHOP_DELIVERY_ORDERS_INDICATORS = ATENCION_CLIENTE_ENDPOINT + "ordersPetshopLogisticIndicators/";
const ATENCION_GET_PETSHOP_LOGISTIC_TRANSPORTER = ATENCION_CLIENTE_ENDPOINT + "transportersByPetshopLogistic/";
const ATENCION_GET_MISSING_DELIVERY_TIME = ATENCION_CLIENTE_ENDPOINT + "missingDeliveryTime/";
const ATENCION_GET_PREPARATION_ORDERS = ATENCION_CLIENTE_ENDPOINT + "preparationOrdersPanel/";
const ATENCION_GET_PREPARATION_ORDERS_INDICATORS = ATENCION_CLIENTE_ENDPOINT + "preparationOrdersPanelIndicators/";
const GET_ZONES_FILTERS = ATENCION_CLIENTE_ENDPOINT + "zoneFiltersPanel/";
const POST_CANCEL_RETURN_ORDER = ATENCION_CLIENTE_ENDPOINT + "cancelReturnOrder/";
const GET_PETSHOP_COMMISSIONS = ATENCION_CLIENTE_ENDPOINT + "petshopCommissions/";
const UPDATE_PETSHOP_COMMISSIONS = ATENCION_CLIENTE_ENDPOINT + "setPetshopCommissions/";
const POST_CATEGORY = ATENCION_CLIENTE_ENDPOINT + "addOrModCategory/";
const DELETE_CATEGORY = ATENCION_CLIENTE_ENDPOINT + "deleteCategory/";
const GET_ORDERS_LOGISTIC_ASSIGNMENT_PANEL = ATENCION_CLIENTE_ENDPOINT + "ordersLogisticAssignmentPanel/";

const DELEGACIONES_POSIBLES_GET = ATENCION_CLIENTE_ENDPOINT + "delegacionesPosibles/";
const DELEGACIONES_MANUAL_POST = ATENCION_CLIENTE_ENDPOINT + "delegarPedido/";
const PRODUCTO_GET_ONE = ATENCION_CLIENTE_ENDPOINT + "producto/";
const PRODUCTOS_EDITAR_POST = ATENCION_CLIENTE_ENDPOINT + "editarProductos/";
const MEGA_BUSCADOR_GET = ATENCION_CLIENTE_ENDPOINT + "megaBuscador/";
const MOTIVOS_COMPRA_CANCELACION = ATENCION_CLIENTE_ENDPOINT + "motivosCancelacionCompra/";
const MOTIVOS_COMPRA_INTERVENCION = ATENCION_CLIENTE_ENDPOINT + "motivosIntervencionCompra/";
const CONSULTA_STOCK = ATENCION_CLIENTE_ENDPOINT + "consultaStock/";
const CONSULTA_RADIO = ATENCION_CLIENTE_ENDPOINT + "consultaRadio/";
const CONSULTA_ENTREGA = ATENCION_CLIENTE_ENDPOINT + "consultaEntrega/";
const ORDERS_SUSPICIUS = ATENCION_CLIENTE_ENDPOINT + "orderSuspicious/";

const PETSHOP_GET_ALL_GET = ATENCION_CLIENTE_ENDPOINT + "petshops/";
const PETSHOPLIST_GET_ALL_GET = ATENCION_CLIENTE_ENDPOINT + "petshopsList/";
const GET_VARIATIONS_CATALOGO = ATENCION_CLIENTE_ENDPOINT + "variacionesNewPanel/";
const NOTIFICATION_POST = ATENCION_CLIENTE_ENDPOINT + "notification/";
const NOTIFICATION_GET = ATENCION_CLIENTE_ENDPOINT + "notifications/";
const NOTE_GET = ATENCION_CLIENTE_ENDPOINT + "notas/";
const NOTE_CS_GET = ATENCION_CLIENTE_ENDPOINT + "notesCustomer/";
const NOTE_POST = ATENCION_CLIENTE_ENDPOINT + "notas/";
const NOTIFICATION_SEND_POST = ATENCION_CLIENTE_ENDPOINT + "sendNotification/";
const NOTIFICATION_HISTORIAL_GET = ATENCION_CLIENTE_ENDPOINT + "notificationhistorial/";
const COMPRA_FOLLOW_POST = ATENCION_CLIENTE_ENDPOINT + "followOrder/";
const COMPRA_FOLLOW_CANCEL_POST = ATENCION_CLIENTE_ENDPOINT + "followOrderCancel/";
const COMPRA_PROBLEM_POST = ATENCION_CLIENTE_ENDPOINT + "problemOrder/";
const REPORT_MAIL_GET = ATENCION_CLIENTE_ENDPOINT + "mails/";
const PETSHOPS_BY_VARIATION_GET = ATENCION_CLIENTE_ENDPOINT + "getPetshopsByProduct/";
const PETSHOP_NEW_POST = ATENCION_CLIENTE_ENDPOINT + "petshopsNew/";
const PETSHOP_EDIT_POST = ATENCION_CLIENTE_ENDPOINT + "petshopsEdit/";
const LIST_PRODUCTS = ATENCION_CLIENTE_ENDPOINT + "listProducts/";
const ONE_PRODUCT = ATENCION_CLIENTE_ENDPOINT + "productoone/";
const LIST_EMPRESAS_PRECIOS_EXTERNOS = ATENCION_CLIENTE_ENDPOINT + "empresaspreciosexternos/";
const UPDATE_PRICING_PRODUCTS = ATENCION_CLIENTE_ENDPOINT + "precios/";
const UPDATE_PRODUCTS_PRICING = ATENCION_CLIENTE_ENDPOINT + "updateProductsPricing/";
const MATCH_PRICING_EXTERNAL_PRODUCTS = ATENCION_CLIENTE_ENDPOINT + "productomatch/";
const SAVE_OR_EDIT = ATENCION_CLIENTE_ENDPOINT + "saveOrEditPrice/";
const GET_LIST_PRODUCTS_PRECIOS_EXTERNOS = ATENCION_CLIENTE_ENDPOINT + "listProductsPricing/";
const GET_LIST_PRODUCTS_VARIATIONS = ATENCION_CLIENTE_ENDPOINT + "productsVariations/";
const COMPRA_PAUSE = ATENCION_CLIENTE_ENDPOINT + "pauseOrder/";
const TO_CONTACT_CS = ATENCION_CLIENTE_ENDPOINT + "toContactByCs/";
const CONTACTED_CS = ATENCION_CLIENTE_ENDPOINT + "contactedByCs/";
const GET_MOTIVOS_TO_CONTACT_CS = ATENCION_CLIENTE_ENDPOINT + "motivosAContactarCS/";
const COMPRA_OPORTUNITY = ATENCION_CLIENTE_ENDPOINT + "oportunity/";
const USER_HAPPINESS = ATENCION_CLIENTE_ENDPOINT + "userHappiness/";
const USER_COMMENTS = ATENCION_CLIENTE_ENDPOINT + "userComments/";
const GET_ROLES = ATENCION_CLIENTE_ENDPOINT + "roles/";
const POST_ROL = ATENCION_CLIENTE_ENDPOINT + "rol/";
const GET_PERMISOS = ATENCION_CLIENTE_ENDPOINT + "permisos/";
const GET_PERMISOS_ROL = ATENCION_CLIENTE_ENDPOINT + "permisosRoles/";
const POST_PERMISO = ATENCION_CLIENTE_ENDPOINT + "permiso/";
const POST_PERMISO_CHECK = ATENCION_CLIENTE_ENDPOINT + "permisosCheck/";
const GET_MENU = ATENCION_CLIENTE_ENDPOINT + "menus/";
const POST_MENU = ATENCION_CLIENTE_ENDPOINT + "menu/";
const GET_MENU_ROL = ATENCION_CLIENTE_ENDPOINT + "menusRoles/";
const POST_MENU_CHECK = ATENCION_CLIENTE_ENDPOINT + "menusCheck/";
const GET_ALLOWED_ROLES = ATENCION_CLIENTE_ENDPOINT + "allowedRolesBycreate/";
const GET_BREEDS = ATENCION_CLIENTE_ENDPOINT + "breeds/";
const POST_ORDER_DIGITALES = ATENCION_CLIENTE_ENDPOINT + "orderDigitales/";
const GET_PETS_PROD_ACTIVE = ATENCION_CLIENTE_ENDPOINT + "empresaproducto/";
const GET_TIPO_DE_PRODUCTO = ATENCION_CLIENTE_ENDPOINT + "variaciones/";
const DELETE_VARIATION = ATENCION_CLIENTE_ENDPOINT + "variationDeleteNewPanel/";
const POST_VARIATION = ATENCION_CLIENTE_ENDPOINT + "variationPostNewPanel/";
const GET_VARIACION_TEMPLATE = ATENCION_CLIENTE_ENDPOINT + "variacion/";
const POST_VARIACION_TEMPLATE = ATENCION_CLIENTE_ENDPOINT + "variacion/";
const GET_SUSCRIPTIONS = ATENCION_CLIENTE_ENDPOINT + "suscriptions/";
const GET_SUSCRIPTION_MOVEMENTS = ATENCION_CLIENTE_ENDPOINT + "suscriptionMovement/";
const PROCESS_PAYMENT_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "processPaymentSuscription/";
const NOTICE_PAYMENT_SUSCRIPTIONS = ATENCION_CLIENTE_ENDPOINT + "noticePaymentSuscriptions/";
const NOTICE_CARDS_EXPIRED = ATENCION_CLIENTE_ENDPOINT + "noticeCardExpired/";
const PAYMENT_MOVEMENT = ATENCION_CLIENTE_ENDPOINT + "paymentMovement/";
const CHECK_PAYMENT_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "checkMovement/";
const ADD_POLICY_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "addPolicySuscription/";
const POST_UNSUBSCRIBE_SUSCRIPTIONS = ATENCION_CLIENTE_ENDPOINT + "unsubscribeSuscriptions/";
const GET_VARIACIONES_LOGISTICA = ATENCION_CLIENTE_ENDPOINT + "variacioneslogistica/";
const POST_VARIACIONES_LOGISTICA = ATENCION_CLIENTE_ENDPOINT + "variacioneslogistica/";
const PETSHOP_GET_ORDERS_CLIENT = ATENCION_CLIENTE_ENDPOINT + "orders/";
const SCHEDULES_ACTIVES = ATENCION_CLIENTE_ENDPOINT + "schedulesActives/";
const PETSHOP_LOGISTIC_CAPACITY = ATENCION_CLIENTE_ENDPOINT + "petshopLogisticCapacity/";
const SEND_VARIATIONS_TEMPLATES = ATENCION_CLIENTE_ENDPOINT + "variationstemplate/";
const GET_SPECIAL_DAY = ATENCION_CLIENTE_ENDPOINT + "specialDay/";
const DELETE_SPECIAL_DAY = ATENCION_CLIENTE_ENDPOINT + "deleteSpecialDay/";
const SEND_SPECIAL_DAY = ATENCION_CLIENTE_ENDPOINT + "sendSpecialDay/";
const SEND_TREGGO_STATUS = ATENCION_CLIENTE_ENDPOINT + "sendTreggoShippingId/";
const GET_UN_SUSCRIPTONS_REASON = ATENCION_CLIENTE_ENDPOINT + "unsubscribeReason/";
const GET_SUSCRIPTONS_Indicators = ATENCION_CLIENTE_ENDPOINT + "suscripcionIndicators/";
const GET_ASSIGNMENT_PROCESS = ATENCION_CLIENTE_ENDPOINT + "movement/";
const GET_PETSHOPS_POLYGONS = ATENCION_CLIENTE_ENDPOINT + "petshops_polygons/";
const RESEND_DATA_SUSCRIPTION = ATENCION_CLIENTE_ENDPOINT + "reSendDataSuscription/";
const GET_USERS_WITH_PAYMENT_PROBLEMS = ATENCION_CLIENTE_ENDPOINT + "userswithpaymentproblems/";
const GET_BLACK_LIST = ATENCION_CLIENTE_ENDPOINT + "blackList/";
const POST_BLACK_LIST = ATENCION_CLIENTE_ENDPOINT + "saveBlackList/"
const ASSIGNATION_SEND_ORDER_TO_COLLETOR = ATENCION_CLIENTE_ENDPOINT + "sendOrderToCollectors/"
const POST_PASS_ORDER_TO_PAYED = ATENCION_CLIENTE_ENDPOINT + "passOrderToPayed/"



//REOUT API REFER
const REFER_CONTROLER = "referred_controller/";
const POST_NEW_BALANCE = REFER_CONTROLER + "newBalance/";
const REFERRED_GET_MOVE_LIST = REFER_CONTROLER + "moveList/";
const REFERRED_GET_ALL_BALANCE = REFER_CONTROLER + "totalBalance/";
const REFERRED_GET_LIST = REFER_CONTROLER + "referredList/";



//ROUTE API SUSCRIPTION
const SUSCRIPTION_CONTROLER = "suscription_controller/";
const GET_SUSCRIPTONS_COLON = SUSCRIPTION_CONTROLER + "suscriptions/";
const GET_REFUNDS_REQUESTED = SUSCRIPTION_CONTROLER + "refunds/";
const GET_REFUNDS_INDICATORS = SUSCRIPTION_CONTROLER + "indicatorsRefunds/";
const SEND_REFUNDS_INSURRANCE_CARRIER = SUSCRIPTION_CONTROLER + "refundInsurranceCarrier/";
const SEND_REFUNDS_FILES = SUSCRIPTION_CONTROLER + "addFilesRefunds/";

//ROUTE API LOGISTIC CONTROLER
const LOGISTIC_CONTROLER = "delivery_controller/";
const GET_ZONE_DELIVERY = LOGISTIC_CONTROLER + "deliveryZones/";
const POST_ZONE_DELIVERY = LOGISTIC_CONTROLER + "saveDeliveryZone/";
const GET_DELIVERY_TIME = LOGISTIC_CONTROLER + "deliveryTimes/";
const POST_PETSHOP_DELIVERY_ZONE = LOGISTIC_CONTROLER + "savePetshopsZone/";
const GET_PETSHOPS_DELIVERY_ZONE = LOGISTIC_CONTROLER + "petshopDelivery/";

//ROUTE API outsourcing delivery
const OUTSOURCING_LOGISTIC_CONTROLER = "outsourcingdelivery_controller/";
const POST_SHIPPINGS_FROM_ORDERS = OUTSOURCING_LOGISTIC_CONTROLER + "createShippingsFromOrders/";
const GET_OUTSOURCE_DELIVERY_ADMIN_ORDERS = OUTSOURCING_LOGISTIC_CONTROLER + "orders/";
const GET_OUTSOURCE_AGREEMENTS = OUTSOURCING_LOGISTIC_CONTROLER + "agreements/";
const GET_OUTSOURCE_TRANSPORTERS_BY_AGREEMENT = OUTSOURCING_LOGISTIC_CONTROLER + "transportersByAgreement/";
const GET_OUTSOURCING_SCHEDULES_ACTIVES = OUTSOURCING_LOGISTIC_CONTROLER + "schedulesActivesOutsourcing/";
const GET_OUTSOURCING_COUNT_ORDERS = OUTSOURCING_LOGISTIC_CONTROLER + "countOrdersPetshop/";
const GET_OUTSOURCING_VIAJES_REPORT = OUTSOURCING_LOGISTIC_CONTROLER + "reportViajes/";
const GET_OUTSOURCING_TRANSPORTER_FILTER = OUTSOURCING_LOGISTIC_CONTROLER + "transportersByAgreement/";


//ROUTE API ATENCION CLIENTE FIXER
const PANEL_FIXER_GET_INDICADORES = ATENCION_CLIENTE_ENDPOINT + "panelFixer/";
const ORDERS_FIXER_GET_ALL = ATENCION_CLIENTE_ENDPOINT + "ordersFixer/";

//ROUTES API NOTIFICATION
const NOTIFICATION_ENDPOINT = "notification_controller/";
const POST_RESPONSE_NOTIFICATION =
  NOTIFICATION_ENDPOINT + "notificationResponse/";

//ROUTES API CONFIG
const CONFIG_ENDPOINT = "config_controller/";
const GET_CONFIGS = CONFIG_ENDPOINT + "configs/";
const POST_CONFIG = CONFIG_ENDPOINT + "config/";

//ROUTES API FINANCES
const FINANCES_ENDPOINT = "finance_controller/";
const GET_TOTAL_VENTAS = FINANCES_ENDPOINT + "ventas/";
const GET_FACTURACION_MENSUAL = FINANCES_ENDPOINT + "facturacion_mensual/";
const GET_PRODUCTS_PRICES = FINANCES_ENDPOINT + "productosprecios/";
const GET_PRODUCTS_COMPRAS_GRAFICO =
  FINANCES_ENDPOINT + "productosPorCompraGrafico/";

//ROUTE API GENERAL_CONTROLER
const GENERAL_CONTROLER = "general_controller/";
const GET_BRANDS_GENERAL = GENERAL_CONTROLER + "marcas/";
const GET_CATEGORY_GENERAL = GENERAL_CONTROLER + "categorias/";
const PRODUCTS_VARIATIONS_GET = GENERAL_CONTROLER + "productsVariations/";
const GET_ZONES_FILTERS_ALL = GENERAL_CONTROLER + "zonesFiltersAll/";
const PRODUCTS_CLEVERTAP_CHANGE_PRICE = ATENCION_CLIENTE_ENDPOINT + "sendClevertapBulletineChangePrice/";

//ROUTES API REPORTS
const REPORT_ENDPOINT = "report_controller/";
const WAREHOUSE_ENDPOINT = 'warehouse_controller/';
const GET_PURCHASE_ORDERS_SIMULATOR = WAREHOUSE_ENDPOINT + "getSimulationPurchaseOrder/";
const GET_ALL_ORDERS_INVOICE = WAREHOUSE_ENDPOINT + "getAllOrderInvoice/";
const POST_SEND_EMAIL_ORDER_INVOICE = WAREHOUSE_ENDPOINT + "sendEmailOrderInvoice/";
const POST_COMPLETE_COMPROBANTE_NUMBER = WAREHOUSE_ENDPOINT + "checkAndCompleteComprobanteNumber/";
const GET_INDICADORES = REPORT_ENDPOINT + "reporteIndicadoresOferta/";
const GET_CANCELED_ORDERS = REPORT_ENDPOINT + "canceledorders/";
const GET_PROBLEM_ORDERS = REPORT_ENDPOINT + "problemorders/";
const GET_PRODUCTS_SOLD = REPORT_ENDPOINT + "productosVendidos/";
const GET_ORDERS_INTERVENED = REPORT_ENDPOINT + "intervened/";
const GET_ORDERS_DELEGATES = REPORT_ENDPOINT + "delegated/";
const GET_PETSHOPS_DELEGATIONS = REPORT_ENDPOINT + "petshopsdelegations/";
const GET_PETSHOPS_DELEGATIONS_BY_PRODUCTS = REPORT_ENDPOINT + "petshopsDelegationsByProducts/";
const GET_INTERVENED_REASONS =
  ATENCION_CLIENTE_ENDPOINT + "motivosIntervencion/";
const GET_DELEGATE_REASONS = ATENCION_CLIENTE_ENDPOINT + "motivosDelegacion/";
const GET_ERROR_INSTALLMENT = REPORT_ENDPOINT + "installmentError/";
const GET_ORDERS_IN_POLYGON = REPORT_ENDPOINT + "ordersInPolygon/";
const GET_PRODUCTS_IN_POLYGON = REPORT_ENDPOINT + "productssoldbypolygon/";
const GET_HEATZONE = REPORT_ENDPOINT + "heatZoneMap/";
const GET_PRODUCTS_BY_PUV_BASED_ON_SALES =
  REPORT_ENDPOINT + "productsbypuvbasedonsales/";
const GET_PETSHOPS_HOURS = REPORT_ENDPOINT + "petshopsHours/";
const GET_GENERAL_ORDERS = REPORT_ENDPOINT + "orders/";
const GET_REPROGRAMED_ORDERS = REPORT_ENDPOINT + "reprogramedorders/";
const GET_MISPICHOS_ORDERS = REPORT_ENDPOINT + "mispichosOrders/";
const GET_MISPICHOS_ORDERS_INDICATORS = REPORT_ENDPOINT + "mispichosOrdersIndicators/";
const GET_COMPLETE_ORDERS_MANUAL = REPORT_ENDPOINT + "ordersCompleteManual/";
const GET_SUSCRIPTIONS_REPORT = REPORT_ENDPOINT + "suscriptions/";
const GET_REPORT_EDITION_BALANCE = REPORT_ENDPOINT + "ordersWithEditionBalance/";
const GET_REPORT_CONTACTED_CS = REPORT_ENDPOINT + "reportContactedByCS/";
const GET_REPORT_CUPONES = REPORT_ENDPOINT + "reportCupones/";
const GET_REPORT_RESCHEDULE_ORDERS = REPORT_ENDPOINT + "reportRescheduleOrders/";
const GET_ALL_CUPONES = REPORT_ENDPOINT + "reportAllCupones/";
const GET_REPORT_FACT_MENSUAL = REPORT_ENDPOINT + "reportFacturacionMensual/";
const GET_REPORT_SUJETOS_SICORE = REPORT_ENDPOINT + "reporteSujetosSicore/";
const GET_REPORT_DFERENCIA_STOCK = REPORT_ENDPOINT + "differencesStock/";
const GET_REPORT_PERCEPCION_SICORE = REPORT_ENDPOINT + "reportePercepcionSicore/";
const GET_REPORT_FABRICA_PAGA = REPORT_ENDPOINT + "reportFabricaTePaga/";
const GET_REPORT_COMISION_DUPLICADA = REPORT_ENDPOINT + "reportComisionDuplicada/";
const GET_REPORT_CUOTAS_SIN_INTERES = REPORT_ENDPOINT + "reportCuotas/";
const GET_PETSHOPS_CAPACITY = REPORT_ENDPOINT + "logisticCapacity/";
const GET_REPORT_OPERATIVE_CONTROL_ORDERS = REPORT_ENDPOINT + "reportOperativeControl/";
const CONTROL_LOGISTIC_DASHBOARD = REPORT_ENDPOINT + 'logisticControlDashboard/';
const PROD_MANUAL_ASSINGMENT = REPORT_ENDPOINT + 'prodManualAssingment/';
const GET_REPORT_PEDIDOS_LOGISTICA = REPORT_ENDPOINT + 'reportPedidosLogistica/';
const GET_MOV_CUENTA_CORRIENTE = REPORT_ENDPOINT + "reportMovCuentaCorriente/";
const GET_REPORT_INGRESOS_PETSHOPS = REPORT_ENDPOINT + "reportIngresosPetshops/";
const GET_TYPE_REPORT = REPORT_ENDPOINT + "typeReport/";
const GET_REPORT_CONTROL_DEVOL = REPORT_ENDPOINT + "reportControlDevoluciones/";
const GET_MASTER_DASHBOARD = REPORT_ENDPOINT + "masterDashboard/";
const GET_SERVICE_LEVEL_MASTER_DASHBOARD = REPORT_ENDPOINT + "getServiceLevelMasterDashboard/";
const GET_PETSHOP_SHARES_DELEGATIONS_DASHBOARD = REPORT_ENDPOINT + "fulfillmentGetDelegationsByPetshop/";
const GET_PRODS_SALES_DELEGATIONS_CATEGORY_DASHBOARD = REPORT_ENDPOINT + "fulfillmentGetProductsSoldAndDelegationsByCat/";
const GET_PRODS_SALES_DELEGATIONS_BRAND_DASHBOARD = REPORT_ENDPOINT + "fulfillmentGetProductsSoldAndDelegationsByBrand/";
const GET_PRODS_SALES_DELEGATIONS_PROD_VAR_DASHBOARD = REPORT_ENDPOINT + "fulfillmentGetProductsSoldAndDelegationsByProdVar/";
const GET_ORDERS_STUCKED_BY_ZONE_DASHBOARD = REPORT_ENDPOINT + "fulfillmentGetOrdersStuckedByZone/";
const GET_DELIVERY_MASTER_DASHBOARD = REPORT_ENDPOINT + "deliveryMasterDashboard/";
const GET_FULFILLMENT_MASTER_DASHBOARD = REPORT_ENDPOINT + "fulfillmentDashboard/";
const GET_ASSUMED_VALUES_DASHBOARD = REPORT_ENDPOINT + "assumedPriceDifferences/";
const GET_REPORT_OUT_TIME_PETSHOPS = REPORT_ENDPOINT + "deliveryProblemsByPetshop/";
const GET_REPORT_VIAJES_COLECTA = REPORT_ENDPOINT + "reportViajesColecta/";
const GET_IMAGES_REPORT_VIAJES_COLECTA = REPORT_ENDPOINT + "imagesReportViajesColecta/";
const GET_REPORT_PRODUCTOS_VENDIDOS_CANTIDAD = REPORT_ENDPOINT + "reportProductosVendidosCantidad/";
const GET_REPORT_RETURN_AND_RETIRE_ORDERS = REPORT_ENDPOINT + "reportDevolutionAndRetireOrders/";
const GET_REPORT_SOLUTION_ORDERS = REPORT_ENDPOINT + "reportSolutionOrders/";
const GET_REPORT_CANCELED_BY_GMV_DETAILED = REPORT_ENDPOINT + "orderCanceledByGMVDetailed/";
const GET_REPORT_CANCELED_BY_GMV_GROUPED = REPORT_ENDPOINT + "orderCanceledByGMVGrouped/";
const GET_USERLIST_REPORT_CANCELED_BY_GMV = REPORT_ENDPOINT + "orderCanceledByGMVUserList/";
const GET_REPORT_CONCILIADOR_PRISMA = REPORT_ENDPOINT + "reportConciliadorPrisma/";
const GET_LOGISTIC_CAPACITY_USED_AND_CONFIGS = REPORT_ENDPOINT + "logisticCapacityUsedAndConfigs/"
const GET_LOGISTIC_CAPACITY_USED_AND_CONFIGS_SPECIAL = REPORT_ENDPOINT + "logisticCapacityUsedAndConfigsSpecial/"
const GET_STATUS_ORDERS_PACKED = REPORT_ENDPOINT + "getInTimeOrDelayedOrdersPacked/"
const GET_PDTE_ACTIVAR_DESACTIVAR = REPORT_ENDPOINT + "getPendienteActivarDesactivar/";
const GET_PETSHOPS_DELIVERY_EFFICIENCY = REPORT_ENDPOINT + "petshopsDeliveryEfficiency/";


// -- report create update pause petshops
const GET_CREATE_UPDATE_PETSHOP_REPORT = REPORT_ENDPOINT + "petshopMovementsReportByDate/";
const GET_OUT_OF_HOURS_REPORT = REPORT_ENDPOINT + "outOfHoursDelivery/";


//ROUTES API LOGISTIC
const LOGISTIC_ENDPOINT = "logistic_controller/";
const ORDERS_SPECIAL_ZONE = LOGISTIC_ENDPOINT + "ordersspecialzone/";

//ROUTES API GENERAL
const GENERAL_CONTROLLER = "general_controller/";
const DELETED_PROD_POST_EDIT = GENERAL_CONTROLLER + "reportEdition/";
const GET_REPROGRAMED_REASONS = GENERAL_CONTROLLER + "reprogramedReasons/";

//ROUTES DISCOUNT
const DISCOUNT_ENDPOINT = "discount_controller/";
const GET_DISCOUNT = DISCOUNT_ENDPOINT + "discounts/";
const SAVE_DISCOUNT = DISCOUNT_ENDPOINT + "discountSave/";
const GET_REFERRED_ACTIVE = DISCOUNT_ENDPOINT + "referredActive/";
const CHANGE_DISCOUNT_STATUS = DISCOUNT_ENDPOINT + "changeStatus/";

//MOTIVOS DLEGACION
const MispichosVars = {
  MOTIVO_DELEGACION_SIN_STOCK: "no tengo stock",
  MOTIVO_DELEGACION_FABRICA: "discontinuado de fábrica",
  MOTIVO_DELEGACION_LOGISTICA: "no puedo entregarlo",
  MOTIVO_DELEGACION_OTROS: "otros",
  MOTIVO_DELEGACION_NOTA: "por nota",
  PROBLEMA_TELEFONO: "el cliente no atendia timbre ni teléfono",
  PROBLEMA_DINERO: "el cliente no tiene el dinero",
  PROBLEMA_LLEGO_TARDE_15: "llego 15 minutos tarde",
  PROBLEMA_LLEGO_TARDE_30: "llego 30 minutos tarde",
  PROBLEMA_LLEGO_TARDE_31: "llego más de 30 minutos tarde",
  /*
  Pedido no llego (no va a entregar pedido)
Pedido incompleto (pedido incompleto)
Producto vencido (incluido en problema con algún producto)
Producto roto (incluido en problema con algún producto)
Producto confundido (incluido en problema con algún producto)
Producto en mal estado (incluido en problema con algún producto)
No entrego factura (otros)
Otros (otros)
  */
  // Problemas
  PROBLEMA_PRODUCTO_DESCONTINUADO: 151,
  PROBLEMA_PETSHOP_OTRO: 68,
  PROBLEMA_PETSHOP_PEDIDO_INCOMPLETO: 69,
  PROBLEMA_PETSHOP_ACEPTA_Y_NO_VA_ENTREGAR: 70,
  PROBLEMA_PETSHOP_PREPARANDO_Y_NO_LLEGO: 73,
  PROBLEMA_PETSHOP_PRODUCTO_MAL_ESTADO: 71,
  PROBLEMA_PETSHOP_ENTREGADO_Y_NO_LLEGO: 74,
  PROBLEMA_CLIENTE_OTRO: 67,
  PROBLEMA_CLIENTE_NO_ACEPTA_PEDIDO: 65,
  PROBLEMA_CLIENTE_SE_CONFUNDE_PRODUCTOS: 64,
  PROBLEMA_CLIENTE_NO_RESPOND: 66,
  PROBLEMA_PETSHOP_DELEGA_POR_PRODUCTO: 235,
  PROBLEMA_PETSHOP_DELEGA_POR_LOGISTICA: 236,
  // -
  MOTIVO_DEVOLUCION_PICHO_PESO: 201,
  MOTIVO_DEVOLUCION_TARJETA: 202,
  PROBLEMA_OTRO_ID: 61,
  PROBLEMA_OTRO: "otro",
  PEDIDO_PROCESANDO: 1,
  PEDIDO_PREPARANDO: 2,
  PEDIDO_ENTREGADO: 8,
  PEDIDO_CANCELADO: 32,
  PEDIDO_CON_PROBLEMA: 33,
  ORDEN_DE_RETIRO_PREPARANDO: 1,
  ORDEN_DE_RETIRO_RETIRADO: 3,
  ORDEN_DE_RETIRO_NO_VA_A_RETIRAR: 5,
  ORDEN_DE_RETIRO_CLIENTE_NO_ESTABA: 4,
  PEDIDO_FALTA_PAGAR: 4,
  MEDIO_PAGO_EFECTIVO: 1,
  MEDIO_PAGO_TARJETA: 2,
  GMAPS_KEY: "AIzaSyBLIM3gCGOS3Gker3wSqRUcvjk4lw2uXF0",
  CATEGORIA_CLASIC: {
    km2: 12,
    horarios: 55, //al meno cubre 10 horarios semanales
    productos: 50,
  },
  CATEGORIA_ORO: {
    km2: 18,
    horarios: 80, //al menos cubre 15 horarios semanales
    productos: 70,
  },
  CATEGORIA_PLATINO: {
    km2: 30,
    horarios: 95, //al menos cubre 17 horarios semanales
    productos: 90,
  },
  petshopsCategories: [
    {
      label: "Master Petshop",
      value: "master-petshop",
    },
    {
      label: "Colector",
      value: "collector",
    },
    {
      label: "Clásico",
      value: "clasico",
    },
  ],
  MAX_QUANTITY: 15
};

export default Mispichoshelper;
export { MispichosVars, ACCOUNT_MANAGER_BBVA_TXT_GET };